import { Component, OnInit } from '@angular/core';
import { type License, type LicenseInstanceExtended } from '@dep/common/core-api/types/license.type';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { LicenseInstanceService } from '@dep/frontend/licenses/services/license-instance.service';
import { type IPopup, PopupService } from '@dep/frontend/services/popup.service';
import { type ICriteria, type ICriteriaList } from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/order.interface';

@Component({
  selector: 'app-licenses-assign',
  templateUrl: './licenses-assign.component.html',
  styleUrl: './licenses-assign.component.scss',
})
export class PopupLicensesAssignComponent implements OnInit {
  /** Popup information, automatically set by `PopupBaseComponent`. */
  public popup?: IPopup;
  public licenses: License[] = [];
  public licenseInstances: LicenseInstanceExtended[] = [];
  public isLoading = false;
  public isProcessingAssignment = false;
  public assignedLicenseId: number | null = null;

  constructor(
    private readonly logger: NGXLogger,
    private readonly popupService: PopupService,
    private readonly licenseInstanceService: LicenseInstanceService,
    private readonly toastr: ToastrService,
    private readonly ngxTranslate: TranslateService,
  ) {
    this.logger.debug('Licenses assign popup initialized');
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    try {
      await this.getLicenseInstances();
      this.licenses = await this.licenseInstanceService.listLicenses();
    } catch (error) {
      this.logger.error('Failed to initialize component', error);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Load license instances for the current orgaentity.
   */
  private async getLicenseInstances(): Promise<void> {
    try {
      const filterQuery = {
        strategy: 'and',
        operator: 'where',
        list: [{
          strategy: 'and',
          operator: 'where',
          list: [{ field: 'orgaentities.id', value: `${this.popup?.settings.customValues?.orgaentityId}` }] as ICriteria[],
        }],
      } satisfies ICriteriaList;

      const queryParams = {
        filter: JSON.stringify(filterQuery),
      };

      const list = await this.licenseInstanceService.listLicenseInstances(queryParams);
      this.licenseInstances = list.items;
    } catch (error) {
      this.logger.error('Failed to load license instances', error);
    }
  }

  /**
   * Assign or unassign a license to the current orgaentity.
   *
   * @param licenseId - The ID of the license to assign or unassign
   */
  public async manageLicenseAssignment(licenseId: number): Promise<void> {
    this.assignedLicenseId = licenseId;
    this.isProcessingAssignment = true;

    try {
      const licenseInstance = this.licenseInstances.find((instance) => instance.licenseId === licenseId);

      // If the license is already assigned, unassign it. Otherwise, assign it.
      if (licenseInstance) {
        await this.licenseInstanceService.unassignLicense(licenseInstance.id);
        this.licenseInstances = this.licenseInstances.filter((instance) => instance.id !== licenseInstance.id);
      } else {
        const assignedLicense = await this.licenseInstanceService.assignLicenses(
          [{ licenseId, orgaentityId: Number(this.popup?.settings.customValues?.orgaentityId), expires: null }],
        );

        this.licenseInstances = [...this.licenseInstances, ...assignedLicense];
      }

      this.toastr.success(
        String(this.ngxTranslate.instant('LICENSES.ASSIGN_LICENSES_POPUP.' + (licenseInstance ? 'UNASSIGN_SUCCESS' : 'ASSIGN_SUCCESS'))),
        String(this.ngxTranslate.instant('LICENSES.ASSIGN_LICENSES_POPUP.' + (licenseInstance ? 'UNASSIGN_SUCCESS_TITLE' : 'ASSIGN_SUCCESS_TITLE'))),
      );
    } catch (error) {
      this.logger.error('Error managing license assignment', error);
      this.toastr.error(
        String(this.ngxTranslate.instant('LICENSES.ASSIGN_LICENSES_POPUP.SAVE_FAILED')),
        String(this.ngxTranslate.instant('LICENSES.ASSIGN_LICENSES_POPUP.SAVE_FAILED_TITLE')),
      );
    } finally {
      this.isProcessingAssignment = false;
    }
  }

  /**
   * Close the popup.
   */
  public onPopupClose(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }
}
