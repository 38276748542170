import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { RoleRight } from '@dep/common/interfaces';
import { RoleRight as ShopRoleRight } from '@dep/common/shop-api/enums/role-right.enum';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { LinkoutsService } from '@dep/frontend/home/services/linkouts.service';
import { AdminRootComponent } from '@dep/frontend/pages/admin/admin-root/admin-root.component';
import { UserTokenService } from '@dep/frontend/services/user-token.service';
import { UserService } from '@dep/frontend/services/user.service';
import { FocusModeService } from '@dep/frontend/shared/services/focus-mode.service';
import { OrdersService } from '@dep/frontend/shop/services/orders.service';
import { SupportFormsService } from '@dep/frontend/support/services/supportforms.service';
import { environment } from 'src/environments/environment';

type MenuItem = {
  name: string;
  slug: string;
  /** Custom function that is called when clicking the menu item. Only supported for external links (`slug` starting with "https://") currently. */
  customFunction?: () => Promise<void>;
  icon?: string;
  subItems?: {
    name: string;
    slug: string;
  }[];
  isExpanded?: boolean;
};

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  public menu: MenuItem[] = [];
  public menuCollapsed = true;
  public isLoading = true;
  public currentSlug?: string;
  public isOrdersIndicatorVisible = false;
  private ordersIndicatorChangedSub$?: Subscription;
  private focusModeSub$?: Subscription;
  public isFocusModeActive = false;
  public activeSubItemIndex?: number;

  constructor(
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly userTokenService: UserTokenService,
    private readonly supportForms: SupportFormsService,
    private readonly ordersService: OrdersService,
    private readonly focusModeService: FocusModeService,
    private readonly linkoutsService: LinkoutsService,
    private readonly ngxTranslate: TranslateService,
    private readonly toastr: ToastrService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.menu = [];
    const menuItems: MenuItem[] = [
      { name: 'ADMIN_MENU.HOME', slug: 'home', icon: 'home.svg' },
      { name: 'ADMIN_MENU.CLIENTS', slug: 'clients', icon: 'cockpit.svg' },
      { name: 'ADMIN_MENU.SHOP', slug: 'shop', icon: 'shop.svg' },
      { name: 'ADMIN_MENU.SHOP_ORDERS', slug: 'shop/orders', icon: 'orders.svg' },
      { name: 'ADMIN_MENU.SUPPORTTICKETS', slug: 'supporttickets', icon: 'support.svg' },
      { name: 'ADMIN_MENU.FILES', slug: 'files', icon: 'downloads.svg' },
      { name: 'ADMIN_MENU.DASHBOARD', slug: 'dashboard', icon: 'dashboard.svg' },
      { name: 'ADMIN_MENU.CONTACTS', slug: 'contacts', icon: 'contacts.svg' },
      {
        name: 'ADMIN_MENU.FORMS.MAIN',
        slug: 'forms/instances',
        icon: 'forms.svg',
        subItems: [
          { name: 'ADMIN_MENU.FORMS.MARKET_AGGREGATED_DATA', slug: 'marketAggregatedData' },
          { name: 'ADMIN_MENU.FORMS.MANUAL_MARKET_DATA', slug: 'manualMarketData' },
        ],
      },
      { name: 'ADMIN_MENU.LICENSES', slug: 'licenses', icon: 'licenses.svg' },
      { name: 'ADMIN_MENU.USERS', slug: 'users' },
      { name: 'ADMIN_MENU.PRODUCTTEMPLATES', slug: 'producttemplates' },
      { name: 'ADMIN_MENU.PRODUCTS', slug: 'products' },
      { name: 'ADMIN_MENU.CONFIGURATION', slug: 'configuration' },
      { name: 'ADMIN_MENU.CURATION_PANEL', slug: 'curation-panel' },
    ];

    const configuredRoutes: Routes = this.router.config;
    const adminRoute = configuredRoutes.find((route) => route.component === AdminRootComponent);

    for (const menuItem of menuItems) {
      const menuRouteConfig = adminRoute?.children?.find((route) => route.path === menuItem.slug);

      // check if route config can be found
      if (menuRouteConfig === undefined) {
        continue;
      }

      // Check if menu item has subitems and one of subitem is the currently open.
      menuItem.isExpanded = (menuItem.subItems || []).length > 0 && this.router.url.includes(menuItem.slug);

      // check if route config contains RoleRight requirements
      if (
        (
          typeof menuRouteConfig.data === 'undefined'
          || (typeof menuRouteConfig.data.rights === 'undefined' && typeof menuRouteConfig.data.rightsOr === 'undefined')
          || !Array.isArray(menuRouteConfig.data.rights)
          || !menuRouteConfig.data.rights.length
        )
        && (!Array.isArray(menuRouteConfig.data?.rightsOr) || !menuRouteConfig.data.rightsOr.length)
      ) {
        this.menu.push(menuItem);
        continue;
      }

      // Check if user meets route config RoleRight requirements.
      // Await in loop is not an issue here because hasRight* uses a cache.
      // eslint-disable-next-line no-await-in-loop
      if (menuRouteConfig.data.rights && await this.userService.hasRightAnd(menuRouteConfig.data.rights as string[])) {
        // If it's a support right check, additionally check if the user sees
        // (has access to) any support forms.
        if (
          menuRouteConfig.data.rights.includes(RoleRight.SUPPORTTICKET_READ)
          // eslint-disable-next-line no-await-in-loop
          && (await this.supportForms.getForms()).length === 0
        ) {
          continue;
        }

        this.menu.push(menuItem);
      }

      // Await in loop is not an issue here because hasRight* uses a cache.
      // eslint-disable-next-line no-await-in-loop
      if (menuRouteConfig.data.rightsOr && await this.userService.hasRightOr(menuRouteConfig.data.rightsOr as string[])) {
        this.menu.push(menuItem);
        continue;
      }
    }

    // Add Sample Studio linkout after the last menu item with icon.
    if (await this.userService.hasRightOr([
      RoleRight.SAMPLE_STUDIO_ADMIN,
      RoleRight.SAMPLE_STUDIO_MAINTAINER,
      RoleRight.SAMPLE_STUDIO_PROCESS_OWNER,
      RoleRight.SAMPLE_STUDIO_PRODUCER,
      RoleRight.SAMPLE_STUDIO_REVIEWER,
      RoleRight.SAMPLE_STUDIO_RD,
      RoleRight.SAMPLE_STUDIO_RETAILER,
    ] as string[])) {
      // Find index of last menu item with icon.
      // TODO: Replace by `.findLastIndex` once the build target was raised to >= es2023.
      const lastItemWithIconIndex = this.menu.reduce((lastItemIndex, item, currentItemIndex) => (item.icon ? currentItemIndex : lastItemIndex), -1);

      this.menu.splice(
        lastItemWithIconIndex + 1,
        0,
        {
          name: 'ADMIN_MENU.SAMPLE_STUDIO',
          slug: environment.config.sampleStudio.url,
          customFunction: async () => {
            const customJWT = await this.userTokenService.generateJWTForExternalLinkout();
            window.open(environment.config.sampleStudio.url + '?token=' + customJWT);
          },
        },
      );
    }

    // Add MAR20X Knowledge Hub (formerly known as "Product Information Platform" and "Web Knowledge Platform") at position 1 (below Home) in the menu array.
    if (await this.userService.hasRight(RoleRight.WEBKNOWLEDGE_READ)) {
      this.menu.splice(
        1,
        0,
        {
          name: 'ADMIN_MENU.PRODUCT_INFORMATION_PLATFORM',
          slug: 'https://',
          customFunction: async () => {
            try {
              await this.linkoutsService.openProductInformationPlatformUrl();
            } catch (error) {
              this.logger.error('Opening MAR20X Knowledge Hub URL failed', error);
              this.toastr.error(
                String(this.ngxTranslate.instant('HOME.QUICK_LINKS.GENERATE_URL_FAILED') + ' (' + error + ')'),
                String(this.ngxTranslate.instant('HOME.QUICK_LINKS.GENERATE_URL_FAILED_TITLE', { name: 'MAR20X Knowledge Hub' })),
              );
            }
          },
          icon: 'product_information_platform.svg',
        },
      );
    }

    // Asynchronously (non-blocking) initialize the orders indicator.
    this.initializeOrdersIndicator();

    this.focusModeSub$ = this.focusModeService.focusModeSubject.subscribe((isFocusModeActive: boolean) => {
      this.isFocusModeActive = isFocusModeActive;
    });
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.ordersIndicatorChangedSub$?.unsubscribe();
    this.focusModeSub$?.unsubscribe();
  }

  /**
   * Initially set `isOrdersIndicatorVisible` and subscribe to the related changes.
   */
  private async initializeOrdersIndicator(): Promise<void> {
    this.isOrdersIndicatorVisible = await this.getOrdersIndicatorState();

    // Subscribe to changes in order status and update the orders indicator accordingly.
    this.ordersIndicatorChangedSub$ = this.ordersService.ordersIndicatorChanged$.subscribe(async () => {
      this.isOrdersIndicatorVisible = await this.getOrdersIndicatorState();
    });
  }

  /**
   * Fetch all orders with status `CREATED`. If there is at least one and the user
   * is either a VS1 oder VS2 user, return `true`.
   *
   * @returns `true` if new orders indicator should be displayed
   */
  private async getOrdersIndicatorState(): Promise<boolean> {
    try {
      const isVS1orVS2User = await this.userService.hasRightOr([
        ShopRoleRight.ShopProductCreate, // Right to determine VS1 user.
        ShopRoleRight.ShopProductFromSharedCreate, // Right to determine VS2 user.
      ]);
      if (!isVS1orVS2User) {
        return false;
      }

      const userId = await this.userService.getIdToken();

      const filterQuery = {
        strategy: 'or',
        operator: 'where',
        list: [
          { field: 'status', value: 'CREATED' },
        ],
      };

      const queryParams = {
        userId: userId?.payload['custom:id'],
        filter: filterQuery,
        limiter: { offset: 0, limit: 1 },
        sorting: { field: 'id', direction: 'ASC' },
      };
      const createdOrders = await this.ordersService.getOrders(queryParams);

      return createdOrders.items.length > 0;
    } catch (error) {
      this.logger.error('Getting orders indicator state failed', error);
      return false;
    }
  }

  public setCurrentSlug(slug: string): void {
    this.currentSlug = slug;
  }

  /**
   * Called when clicking on a regular menu item (not on sub-items or "https://" links).
   *
   * @param menuItem - Menu item that was clicked
   */
  public async onRegularMenuItemClick(menuItem: MenuItem): Promise<void> {
    if (menuItem.subItems) {
      menuItem.isExpanded = !menuItem.isExpanded;
    } else {
      this.menuCollapsed = true;
    }

    this.setCurrentSlug(menuItem.slug);
  }

  /**
   * Called when clicking on an external link menu item.
   *
   * Handles `customFunction` invocation.
   *
   * @param menuItem - Menu item that was clicked
   */
  public onExternalLinkMenuItemClick(menuItem: MenuItem, $event: MouseEvent): void {
    // If the menu item has a custom function (to process anything before opening a page for example), call it.
    if (typeof menuItem.customFunction === 'function') {
      // Prevent normal routing if a custom function is set.
      $event.preventDefault();

      // Use loading indicator to show the user that something is still loading.
      this.isLoading = true;
      menuItem.customFunction().then(() => {
        this.isLoading = false;
      });
    }
  }
}
