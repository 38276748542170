<footer class="row footer user-select-none">
  <div class="col-4 languages">
    <div class="dropup">
      <button type="button" class="dropbtn"><div class="iti__flag iti__{{
        language === 'en' ? 'us'
        : language === 'zh' ? 'cn'
        : language === 'ko' ? 'kr'
        : language === 'vi' ? 'vn'
        : language === 'ja' ? 'jp'
        : language === 'da' ? 'dk'
        : language === 'sv' ? 'se'
        : language
      }} mr-1"></div> {{ 'FOOTER.' + getLanguageName(language) | translate }} <img class="arrowup" src="assets/icons/arrow_up.png" srcset="assets/icons/arrow_up@2x.png 2x"></button>
      <div class="dropup-content">
        <div *ngFor="let languageCode of allLanguages">
          <a href="javascript: void(0)" (click)="changeLanguage(languageCode.name)"><div class="iti__flag iti__{{ languageCode.icon }} mr-1"></div> {{ 'FOOTER.' + languageCode.name | translate }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8 text-right">
    <a (click)="showProviderModal()" href="javascript: void(0)">{{ 'FOOTER.PROVIDER' | translate }}</a>
    <a (click)="showLegalModal()" href="javascript: void(0)">{{ 'FOOTER.LEGAL' | translate }}</a>
    <a (click)="showPrivacyModal()" href="javascript: void(0)">{{ 'FOOTER.PRIVACY' | translate }}</a>
    <br class="d-md-none" />
    <span class="mr-1 mr-md-0" [title]="'Version ' + version + stageSuffix">{{ 'FOOTER.COPYRIGHT' | translate }}</span>
  </div>
</footer>

<app-maintenance></app-maintenance>
