/* eslint-disable import/order */
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService as AngularCookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MBUIModule } from './mbui/mbui.module';
import { NgModule } from '@angular/core';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import localeENGB from '@angular/common/locales/en-GB';
import packageInfo from '../../package.json';

// Services
import { CookieService } from '@dep/frontend/services/cookie.service';
import { LoggerWriterService } from '@dep/frontend/services/loggerwriter.service';
import { NavHistoryService } from '@dep/frontend/services/navhistory.service';

// Pages & Components
import { AdminRootComponent } from './pages/admin/admin-root/admin-root.component';
import { AuthGuard } from './services/auth.guard';
import { AutoalertSubscriptionModalComponent } from './blocks/autoalert-subscription-modal/autoalert-subscription-modal.component';
import { BannerComponent } from './blocks/banner/banner.component';
import { ClientsListComponent } from './pages/admin/clients-list/clients-list.component';
import { ConfigurationComponent } from './pages/admin/configuration/configuration.component';
import { ConnectedshowroomComponent } from './pages/admin/connectedshowroom/connectedshowroom.component';
import { ConnectedShowroomQrCodeGeneratorComponent } from './pages/admin/connectedshowroom/qrcode-generator/qrcode-generator.component';
import { ContactsDetailsComponent } from './pages/admin/contacts-details/contacts-details.component';
import { ContactsListComponent } from './pages/admin/contacts-list/contacts-list.component';
import { CurationPanelComponent } from './pages/curation-panel/curation-panel.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HelpDeskComponent } from './blocks/help-desk/help-desk.component';
// import { LazyTranslateLoader } from './lazy-translate-loader';
import { LoginComponent } from './pages/login/login.component';
import { LoginInternalComponent } from './pages/login-internal/login-internal.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MaintenanceComponent } from './blocks/maintenance/maintenance.component';
import { PopupBaseComponent } from './popups/popup-base.component';
import { PopupEditDealerInformationComponent } from './popups/edit-dealer-information/edit-dealer-information.component';
import { PopupEnergyManagementComponent } from './popups/energy-management/energy-management.component';
import { PopupGenericConfirmationComponent } from './popups/generic-confirmation/generic-confirmation.component';
import { PopupGenericMessageComponent } from './popups/generic-message/generic-message.component';
import { PopupHeaderComponent } from './popups/header/header.component';
import { PopupRCPSSettingsComponent } from './popups/rcps-settings/rcps-settings.component';
import { PopupEditClientsComponent } from './popups/edit-clients/edit-clients.component';
import { PopupRcpsContentEditComponent } from './popups/rcps-content-edit/rcps-content-edit.component';
import { PopupPlayerRestartComponent } from './popups/player-restart/player-restart.component';
import { PopupProductChooserComponent } from './popups/product-chooser/product-chooser.component';
import { RolesManagerPopupComponent } from './popups/roles-manager/roles-manager.component';
import { ProvideParentFormDirective } from './app.provideparentform.directive';
import { RegistrationComponent } from './pages/registration/registration.component';
import { SharedModule } from './shared/shared.module';
import { SideNavigationComponent } from './blocks/side-navigation/side-navigation.component';
import { TimeRangeSliderComponent } from '@dep/frontend/popups/energy-management/time-range-slider/time-range-slider.component';
import { UsersDetailsComponent } from './pages/admin/users-details/users-details.component';
import { UsersListComponent } from './pages/admin/users-list/users-list.component';
import { PopupNewContactComponent } from '@dep/frontend/popups/new-contact-popup/new-contact-popup.component';
import { PopupWindows11UpgradeProductChooserComponent } from './popups/windows11-upgrade-product-chooser/windows11-upgrade-product-chooser.component';
import { PopupLicensesAssignComponent } from './popups/licenses-assign/licenses-assign.component';

// Pipes
import { AsClientPipe } from './pipes/as-client.pipe';
import { OrgaentityDataPipe } from './pipes/orgaentity-data.pipe';
import { ClientHasLicensePipe } from './pipes/client-has-license.pipe';
import { ClientHasSupportTicketFormPipe } from './pipes/client-has-support-ticket-form.pipe';
import { ClientIconColorPipe } from './pipes/client-icon-color.pipe';
import { ClientIsIOTAssignedPipe } from './pipes/client-is-iot-assigned.pipe';
import { ClientIsRCPSPlayerPipe } from './pipes/client-is-rcps-player.pipe';
import { ClientIsRenewablePipe } from './pipes/client-is-renewable.pipe';
import { ClientIsUpgradeableToWindows11Pipe } from './pipes/client-is-upgradeable-to-windows11.pipe';
import { ClientMetricValuePipe } from './pipes/client-metric-value.pipe';
import { ClientTypeAbbreviationPipe } from './pipes/client-type-abbreviation.pipe';
import { ClientValidUntilPipe } from './pipes/client-valid-until.pipe';
import { DateDayBeforePipe } from './pipes/date-day-before.pipe';
import { RowHasContextMenuPipe } from './pipes/row-has-context-menu.pipe';
import { RowIsNodePipe } from './pipes/row-is-node.pipe';
import { UserHasRightPipe } from './pipes/user-has-right.pipe';
import { ClientsListInsightsComponent } from './pages/admin/clients-list/insights/insights.component';
import { ClientIsEnergyManagementEnabled } from './pipes/client-is-energy-assigned.pipe';
import { PopupBillingAddressComponent } from './popups/billing-address/billing-address-popup.component';
import { BillingAddressWarningIsShownPipe } from './pipes/billing-address-warning-is-shown.pipe';
import { ClientMetricColorPipe } from './pipes/client-metric-color.pipe';
import { ClientOnlineStatusPipe } from './pipes/client-online-status.pipe';
import { Windows11ReadyStateValuePipe } from './pipes/windows11-ready-state-value.pipe';
import { LicenseIconPipe } from './licenses/pipes/license-icon.pipe';
import { IsLicenseAssignedPipe } from './pipes/is-license-assigned.pipe';

registerLocaleData(localeENGB);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../i18n/', '.json');
}

export const ANGULAR_MATERIAL_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AdminRootComponent,
    AppComponent,
    AsClientPipe,
    AutoalertSubscriptionModalComponent,
    BannerComponent,
    BillingAddressWarningIsShownPipe,
    ClientHasLicensePipe,
    ClientHasSupportTicketFormPipe,
    ClientIconColorPipe,
    ClientIsEnergyManagementEnabled,
    ClientIsIOTAssignedPipe,
    ClientIsRCPSPlayerPipe,
    ClientIsRenewablePipe,
    ClientIsUpgradeableToWindows11Pipe,
    ClientMetricColorPipe,
    ClientMetricValuePipe,
    ClientOnlineStatusPipe,
    ClientsListComponent,
    ClientsListInsightsComponent,
    ClientTypeAbbreviationPipe,
    ClientValidUntilPipe,
    ConfigurationComponent,
    ConnectedshowroomComponent,
    ConnectedShowroomQrCodeGeneratorComponent,
    ContactsDetailsComponent,
    ContactsListComponent,
    CurationPanelComponent,
    DashboardComponent,
    DateDayBeforePipe,
    FooterComponent,
    HeaderComponent,
    HelpDeskComponent,
    IsLicenseAssignedPipe,
    LoginComponent,
    LoginInternalComponent,
    LogoutComponent,
    MaintenanceComponent,
    OrgaentityDataPipe,
    PopupBaseComponent,
    PopupBillingAddressComponent,
    PopupEditClientsComponent,
    PopupEditDealerInformationComponent,
    PopupEnergyManagementComponent,
    PopupGenericConfirmationComponent,
    PopupGenericMessageComponent,
    PopupHeaderComponent,
    PopupLicensesAssignComponent,
    PopupNewContactComponent,
    PopupPlayerRestartComponent,
    PopupProductChooserComponent,
    PopupRcpsContentEditComponent,
    PopupRCPSSettingsComponent,
    PopupWindows11UpgradeProductChooserComponent,
    ProvideParentFormDirective,
    RegistrationComponent,
    RolesManagerPopupComponent,
    RowHasContextMenuPipe,
    RowIsNodePipe,
    SideNavigationComponent,
    TimeRangeSliderComponent,
    UserHasRightPipe,
    UsersDetailsComponent,
    UsersListComponent,
    Windows11ReadyStateValuePipe,
  ],
  bootstrap: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    LoggerModule.forRoot({
      level: environment.stage === 'prod' ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      // serverLoggingUrl: '/api/logs',
      // serverLogLevel: NgxLoggerLevel.ERROR
    }, {
      writerProvider: { provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: LoggerWriterService },
    }),
    FormsModule,
    LicenseIconPipe,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTabsModule,
    MatTooltipModule,
    MBUIModule,
    NgSelectModule,
    OverlayModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 7000,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        // useClass: LazyTranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './i18n/', '.json?v=' + packageInfo.version),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AngularCookieService,
    AuthGuard,
    ClientMetricValuePipe,
    ClientOnlineStatusPipe,
    CookieService,
    NavHistoryService,
    Windows11ReadyStateValuePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: ANGULAR_MATERIAL_DATE_FORMATS,
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true,
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(private config: NgSelectConfig) {
    // Set the default `bindValue` of ng-select to "value".
    this.config.bindValue = 'value';
  }
}
