import { Component, OnInit } from '@angular/core';
import { Windows11UpgradeProductIndentifier } from '@dep/common/shop-api/enums/product.enum';
import { NGXLogger } from 'ngx-logger';

import { type IPopup, PopupService } from '@dep/frontend/services/popup.service';
import { type Windows11UpgradeAlertMessage } from '@dep/frontend/services/windows11-upgrade.service';

@Component({
  selector: 'app-popup-windows11-upgrade-product-chooser',
  templateUrl: './windows11-upgrade-product-chooser.component.html',
  styleUrl: './windows11-upgrade-product-chooser.component.scss',
})
export class PopupWindows11UpgradeProductChooserComponent implements OnInit {
  /** Popup information, automatically set by `PopupBaseComponent`. */
  public popup?: IPopup;
  public selectedProduct?: Windows11UpgradeProductIndentifier;
  public products: { identifier: Windows11UpgradeProductIndentifier; description: string; selectedEligiblePlayers?: string[]; }[] = [];
  public alertMessages: Windows11UpgradeAlertMessage[] = [];

  constructor(
    private logger: NGXLogger,
    private popupService: PopupService,
  ) { }

  public ngOnInit(): void {
    if (this.popup?.settings.customValues?.products) {
      try {
        this.products = JSON.parse(this.popup.settings.customValues.products);
      } catch (error) {
        this.logger.error('Failed to parse products from popup settings', error);
      }
    }

    if (this.popup?.settings.customValues?.alertMessages) {
      this.alertMessages = JSON.parse(this.popup.settings.customValues.alertMessages);
    }
  }

  public onProductClick(product: { identifier: Windows11UpgradeProductIndentifier; description: string; selectedEligiblePlayers?: string[]; }): void {
    // Do not allow selecting Windows 11 upgrade products without eligible players.
    if (!product.selectedEligiblePlayers) {
      return;
    }

    if (this.selectedProduct !== product.identifier) {
      this.selectedProduct = product.identifier;
    } else {
      this.selectedProduct = undefined;
    }
  }

  /**
   * Close the popup without submitting a selected product.
   */
  public onPopupClose(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }

  /**
   * Send selected product to the callback and close the popup.
   */
  public onProductSubmit(): void {
    if (this.popup && typeof this.popup.callbacks.selectedProduct === 'function') {
      this.logger.debug('Triggering callback selectedElement', this.selectedProduct);
      this.popup.callbacks.selectedProduct(this.selectedProduct);

      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not trigger callback selectedElement', this.popup);
    }
  }
}
