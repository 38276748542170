import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getCountries } from '@dep/common/countries';
import { IUserCreateInput, LocationIDTypeObj } from '@dep/common/interfaces';
import { NgOption } from '@ng-select/ng-select';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { ModalComponentOptions } from '@dep/frontend/blocks/info-modal/info-modal.component';
import { AppsyncService } from '@dep/frontend/services/appsync.service';
import {
  getAllCountryCodes,
  getLanguageCodeByCountryCode,
  languages,
} from '@dep/frontend/services/countries';
import { TranslationService } from '@dep/frontend/services/translation.service';
import { UsersService } from '@dep/frontend/services/users.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @ViewChild('phone_number', { static: false }) public phoneNumber?: ElementRef;
  public infoModal?: ModalComponentOptions;
  public formDisabled = false;
  public submitLoading = false;
  public routeParamsSub$?: Subscription;
  public servicesAreExpanded = false;
  public ssoReroute = false;

  public selectCountries: NgOption[] = [];
  public selectServices: NgOption[] = [];

  public jobtitles = [
    'IT',
    'SALES',
    'PRODUCT_EXPERT',
    'MANAGEMENT',
    'FACILITY_MANAGEMENT',
    'MEDIA_EXPERT',
  ];

  public companyDetails = [{
    address: '',
    street: '',
    number: '',
    zip: '',
    city: '',
    country: null,
  }];

  // Personal data inputs
  public model: IUserCreateInput = {
    firstname: '',
    lastname: '',
    email: '',
    services: [],
    phone: '',
    jobtitle: null,
    requestId: this.appsyncService.generateRandomString(10),
    country: '',
    language: '',
    locations: [{
      companyAddress: '',
      locationIdType: 'GSSN',
      locationIdValue: '',
    }],

    // Billing data inputs
    contact: {
      id: -1,
      name: '',
      street: '',
      city: '',
      zip: '',
      country: 'DE',
      vatId: '',
      mail: '',
      phone: '',
      gssn: '',
      requestId: this.appsyncService.generateRandomString(10),
    },
  };

  public orgaentitiesIds: any;

  public translSaveSuccessTitle = '';
  public translSaveSuccessText = '';
  public translSSOSaveSuccessText = '';
  public translSaveErrorTitle = '';
  public translSaveErrorGSSNText = '';
  public translSaveErrorDuplicateTextDefault = '';
  public translSaveErrorDuplicateTextUsername = '';
  public translSaveErrorDuplicateTextEmail = '';
  public countryCode = '';
  public allCountryCodes: Array<string> = [];
  public allLanguageCodes: Array<string> = [];
  public selectLocationIDType: NgOption[] = [];

  constructor(
    private usersService: UsersService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private appsyncService: AppsyncService,
    private translationService: TranslationService,
    private ngxTranslate: TranslateService,
  ) {
    this.resetModal();

    const countriesCodes = Object.entries(getCountries());
    this.selectCountries = countriesCodes.map((c) => ({
      value: c[0],
      label: c[1],
    }));
    this.loadTranslations();
  }

  public ngOnInit(): void {
    this.routeParamsSub$ = this.route.params.subscribe({
      next: (params) => {
        this.model.username = params.username;
        this.model.sub = params.sub;
        this.ssoReroute = params.type === 'sso';
      },
    });
    this.allCountryCodes = getAllCountryCodes().map((cc) => getLanguageCodeByCountryCode(cc));

    this.allLanguageCodes = [];
    for (const langCode in languages) {
      if (languages.hasOwnProperty(langCode)) {
        this.allLanguageCodes.push(languages[langCode].icon);
      }
    }

    // TODO: Make services select dynamic.
    // for (const item in AccountService) {
    //   if (isNaN(Number(item))) {
    //     this.selectServices.push(
    //       { id: item,
    //         text: AccountService[item]}
    //     );
    //   }
    // }
    for (const item in LocationIDTypeObj) {
      if (Number.isNaN(Number(item))) {
        if (item === 'GSSN') { // add only 'GSSN'
          this.selectLocationIDType.push(
            {
              value: item,
              label: item,
            },
          );
        }
      }
    }

    this.model.language = this.translationService.getLanguage();
    this.ngxTranslate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.model.language = event.lang;
      this.logger.debug('Model language changed', this.model.language);
    });
  }

  private loadTranslations(): void {
    this.translationService
      .get([
        'REGISTER.SAVE_SUCCESS_TITLE',
        'REGISTER.SAVE_SUCCESS',
        'REGISTER.SSO_SAVE_SUCCESS',
        'REGISTER.SAVE_ERROR_TITLE',
        'REGISTER.SAVE_ERROR_GSSN',
        'REGISTER.SAVE_ERROR_DUPLICATE_DEFAULT',
        'REGISTER.SAVE_ERROR_DUPLICATE_USERNAME',
        'REGISTER.SAVE_ERROR_DUPLICATE_EMAIL',
        'REGISTER.SERVICES.DIGITALSIGNAGE',
        'REGISTER.SERVICES.HANDOVERCOMPANION',
        'REGISTER.SERVICES.NETWORKREPORTING',
      ])
      .subscribe({
        next: (texts: { [key: string]: string }) => {
          this.translSaveSuccessTitle = texts['REGISTER.SAVE_SUCCESS_TITLE'];
          this.translSaveSuccessText = texts['REGISTER.SAVE_SUCCESS'];
          this.translSSOSaveSuccessText = texts['REGISTER.SSO_SAVE_SUCCESS'];
          this.translSaveErrorTitle = texts['REGISTER.SAVE_ERROR_TITLE'];
          this.translSaveErrorGSSNText = texts['REGISTER.SAVE_ERROR_GSSN'];
          this.translSaveErrorDuplicateTextDefault = texts['REGISTER.SAVE_ERROR_DUPLICATE_DEFAULT'];
          this.translSaveErrorDuplicateTextUsername = texts['REGISTER.SAVE_ERROR_DUPLICATE_USERNAME'];
          this.translSaveErrorDuplicateTextEmail = texts['REGISTER.SAVE_ERROR_DUPLICATE_EMAIL'];

          this.selectServices = [
            {
              value: 'DIGITALSIGNAGE',
              label: texts['REGISTER.SERVICES.DIGITALSIGNAGE'],
            },
            {
              value: 'HANDOVERCOMPANION',
              label: texts['REGISTER.SERVICES.HANDOVERCOMPANION'],
            },
            {
              value: 'NETWORKREPORTING',
              label: texts['REGISTER.SERVICES.NETWORKREPORTING'],
            },
          ];
        },
      });
  }

  /**
   * Add 'VF' to Location ID type only when selected country is Germany (DE)
   */
  public onCountrySelectedChanged(event: string | string[]): void {
    if (event && event === 'DE') {
      this.selectLocationIDType = [...this.selectLocationIDType, { value: 'VF', label: 'VF' }];
    } else {
      this.selectLocationIDType = this.selectLocationIDType.filter((v) => (v.value !== 'VF'));
    }
  }

  /**
   * Send user sign-up information to the API, handle loading state, and show info
   * or error messages.
   */
  public async register(): Promise<void> {
    this.logger.debug('Calling user signup function via API Gateway');
    const newUser = JSON.parse(JSON.stringify(this.model)) as IUserCreateInput;
    this.logger.debug('Registering new user', newUser);

    for (const i in this.companyDetails) {
      if (this.companyDetails.hasOwnProperty(i)) {
        if (newUser.locations && newUser.locations[i]) {
          newUser.locations[i].companyAddress += '\n'
            + this.companyDetails[i].address + '\n'
            + this.companyDetails[i].street + ' '
            + this.companyDetails[i].number + '\n'
            + this.companyDetails[i].zip + ' '
            + this.companyDetails[i].city + '\n'
            + this.companyDetails[i].country;
        }
      }
    }
    this.logger.debug('Creating user with model', newUser);
    this.submitLoading = true;

    try {
      await this.usersService.signup(newUser);

      this.showSuccessModal(
        this.translSaveSuccessTitle,
        this.ssoReroute ? this.translSSOSaveSuccessText : this.translSaveSuccessText,
      );
    } catch (error: any) {
      let newUserError = error;
      this.logger.error('User register error', newUserError);
      if (
        typeof newUserError.error === 'string'
        && newUserError.error.indexOf('ERR_GSSN_NO_MATCH: ') === 0
      ) {
        newUserError = this.translSaveErrorGSSNText;
      } else if (
        typeof newUserError.error === 'string'
        && newUserError.error.indexOf('ERR_DUPLICATE: ') === 0
      ) {
        if (newUserError.error.indexOf('SAVE_ERROR_DUPLICATE_USERNAME')) {
          newUserError = this.translSaveErrorDuplicateTextUsername;
        } else if (newUserError.error.indexOf('SAVE_ERROR_DUPLICATE_EMAIL')) {
          newUserError = this.translSaveErrorDuplicateTextEmail;
        } else {
          newUserError = this.translSaveErrorDuplicateTextDefault;
        }
      } else if (newUserError.error && newUserError.error.msg) {
        newUserError = newUserError.error.msg;
      }
      this.logger.error('User register error', newUserError);
      this.showErrorModal(
        this.translSaveErrorTitle,
        typeof newUserError !== 'string' ? String(newUserError.error) : newUserError,
      );

      this.model.requestId = this.appsyncService.generateRandomString(10);
      if (this.model.contact) {
        this.model.contact.requestId = this.appsyncService.generateRandomString(10);
      }
    } finally {
      this.submitLoading = false;
    }
  }

  private showSuccessModal(title: string, content = ''): void {
    this.infoModal = {
      dialogType: 'success',
      title,
      content,
      visible: true,
      dismiss: () => {
        this.resetModal();

        this.router.navigate(['login']);
      },
      dismissButtonText: 'OK',
      confirm: undefined,
      confirmButtonText: undefined,
    };
  }

  private showErrorModal(title: string, content = ''): void {
    this.infoModal = {
      dialogType: 'error',
      title,
      content,
      visible: true,
      dismiss: () => {
        this.resetModal();
      },
      dismissButtonText: 'Cancel',
      confirm: undefined,
      confirmButtonText: undefined,
    };
  }

  private resetModal(): void {
    this.logger.debug('Modal reset');

    this.infoModal = {
      visible: false,
      title: '',
      content: '',
      dismiss: () => {},
      dismissButtonText: undefined,
      confirm: undefined,
      confirmButtonText: '',
    };
  }

  public changeLanguage(countryCode: string): void {
    this.logger.debug('Changing language by registry form');
    this.translationService.changeLanguage(getLanguageCodeByCountryCode(countryCode), true);
  }

  public addLocation(): void {
    if (!this.model.locations) {
      this.model.locations = [];
    }

    this.model.locations.push({
      companyAddress: '',
      locationIdType: 'GSSN',
      locationIdValue: '',
    });

    this.companyDetails.push({
      address: '',
      street: '',
      number: '',
      zip: '',
      city: '',
      country: null,
    });
  }

  public removeLocation(index: number): void {
    if (this.model.locations) {
      this.model.locations.splice(index, 1);
    }
    this.companyDetails.splice(index, 1);
  }

  /**
   * Checks that at least one of the services in "Select Service(s)" has been selected.
   */
  public isServicesSet(): boolean {
    return !!this.model.services && this.model.services.length > 0;
  }

  /**
   * Checks that at least one of the ID Type in "Location Data" has been selected.
   */
  public isLocationIdTypeSet(): boolean {
    if (this.model.locations) {
      for (let i = 0; i < this.model.locations.length; i++) {
        if (this.model.locations[i].locationIdType == null) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Returns the corresponding country code for the given language code.
   */
  public getCountryCodeByLangCode(langCode?: string): string {
    return languages[langCode || 'en'].icon;
  }
}
