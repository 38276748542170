<h1 class="mb-4">{{ 'LICENSES.ASSIGN_LICENSES_POPUP.TITLE' | translate }}</h1>
@if (isLoading) {
  <div class="loading loading-center mt-3"></div>
}
@if (licenses.length > 0) {
  <div class="font-weight-bold mb-1 pt-3">{{ 'LICENSES.ASSIGN_LICENSES_POPUP.LABEL' | translate }}</div>
  <ul class="license-list">
    @for (license of licenses; track license.id; let first = $first) {
      <li class="license-item" [class.top-border]="first">
        <div class="license-info">
          <span class="license-name">{{ license.name }}</span>
          <div class="icon-wrapper">
            <img src="{{ license | licenseIcon }}.svg" alt="Icon" />
          </div>
        </div>
        @if (isProcessingAssignment && assignedLicenseId === license.id) {
          <span class="loading loading-btn"></span>
        }
        <button
          type="button"
          class="btn btn-sm"
          [ngClass]="(license.id | isLicenseAssigned: licenseInstances) ? 'btn-danger' : 'btn-success'"
          (click)="manageLicenseAssignment(license.id)"
          [disabled]="isProcessingAssignment && assignedLicenseId === license.id"
        >
          {{
            (license.id | isLicenseAssigned: licenseInstances)
            ? ('LICENSES.ASSIGN_LICENSES_POPUP.UNASSIGN' | translate)
            : ('LICENSES.ASSIGN_LICENSES_POPUP.ASSIGN' | translate)
          }}
        </button>
      </li>
    }
  </ul>
}
@else if (licenses.length === 0 && !isLoading) {
  <span>{{ 'LICENSES.ASSIGN_LICENSES_POPUP.NO_LICENSES' | translate }}</span>
}
<hr>
<div class="text-right">
  <button
    type="button"
    class="btn btn-sm btn-primary"
    (click)="onPopupClose()"
  >
    {{ 'LICENSES.ASSIGN_LICENSES_POPUP.CLOSE' | translate }}
  </button>
</div>
