<nav class="d-flex navigation-bar align-items-end user-select-none">
  <div class="mb-2">
    <span class="mr-3 mr-sm-5">
      <a [routerLink]="isLoggedIn ? '/home' : '/login'">
        <img src="assets/icons/header_logo.png" srcset="assets/icons/header_logo@2x.png 2x" alt="Logo" />
      </a>
    </span>
    <a class="align-bottom" [routerLink]="isLoggedIn ? '/home' : '/login'">
      <span class="title">{{ 'HEADER.TITLE' | translate }}</span>
    </a>
  </div>
  <div class="ml-auto d-print-none">
    @if (orderProductsCount) {
      <button
        type="button"
        class="btn mr-3 pt-1 font-weight-normal"
        (click)="navigateToBasket()"
      >
        <img src="assets/icons/basket.png" alt="Basket" class="align-text-top">
        <span class="ml-2 username" for="Basket" role="button">{{ 'HEADER.BASKET' | translate }}</span>
        <span class="products-sum-indicator">
          <span>{{ orderProductsCount }}</span>
        </span>
      </button>
    }
    @if (isLoggedIn) {
      <a class="dropdown align-text-top" [routerLink]="[]">
        <img class="mr-2 mb-1" src="assets/icons/user.svg">
        <span class="username d-none d-md-inline-block mr-2">{{ username }}</span>
        <img class="mb-1" src="assets/icons/arrow_down.svg">
        <div class="dropdown-content">
          <a [routerLink]="[]" (click)="openRolesManager()"><img class="mr-2 align-text-top" src="assets/icons/roles.svg" alt="My Roles">{{ 'HEADER.MY_ROLES' | translate }}</a>
          <a [routerLink]="[]" (click)="logout()"><img class="ml-1 mr-2 align-text-top" src="assets/icons/logout.svg" alt="Logout">{{ 'HEADER.LOGOUT' | translate }}</a>
        </div>
      </a>
    }
  </div>
</nav>

<!-- TODO: Will be enabled again when a new banner is provided. (Disabled in March 2023) -->
<!-- <app-banner *ngIf="isLoggedIn"></app-banner> -->
