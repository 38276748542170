import { Pipe, PipeTransform } from '@angular/core';
import { LicenseInstanceExtended } from '@dep/common/core-api/types/license.type';

@Pipe({
  name: 'isLicenseAssigned',
})
export class IsLicenseAssignedPipe implements PipeTransform {
  public transform(licenseId: number, licenseInstances: LicenseInstanceExtended[]): boolean {
    return licenseInstances.some((instance) => instance.licenseId === licenseId);
  }
}
