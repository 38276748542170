@if (form) {
  <form [formGroup]="form">
    <!-- Header -->
    <div class="modal-header p-0 mb-3 border-0">
      <div class="modal-title row">
        <h3 class="col">
          {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.TITLE' | translate }}
        </h3>
        @if (popup && popup.settings.customValues) {
          <div class="mt-2 px-2">
            <img
              src="assets/icons/client_{{ popup.settings.customValues.clientAbbreviation }}_{{ popup.settings.customValues.clientColor }}.svg"
              class="client-icon mr-2"
              [title]="'ID: ' + popup.settings.customValues.orgaentityId"
            />
            <span style="color: #9F9F9F;">{{ popup.settings.customValues.playername }}</span>
          </div>
        }
      </div>
    </div>

    <div class="modal-body p-0">
      @if (!isSuccessSaved.workgroup) {
        <div>
          <span class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
        </div>
      }

      @if (canViewAndUpdate.hostname || canViewAndUpdate.retailerPlayerName) {
        <div class="row">
          <!-- Retailer player name input -->
          @if (canViewAndUpdate.retailerPlayerName) {
            <div class="col-md-6 col-sm-12 form-group pr-md-3">
              <label for="retailerPlayerName">
                {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.RETAILER_PLAYER_NAME.LABEL' | translate }}
              </label>
              <input
                type="text"
                id="retailerPlayerName"
                name="retailerPlayerName"
                formControlName="retailerPlayerName"
                class="form-control form-control-sm"
                [class.is-invalid]="form.get('retailerPlayerName')?.invalid"
                [disabled]="true"
              />
            </div>
          }

          <!-- Hostname input (changing hostname not implemented yet) -->
          @if (canViewAndUpdate.hostname) {
            <div class="col-md-6 col-sm-12 form-group pr-md-3">
              <label for="hostname">
                {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.HOSTNAME.LABEL' | translate }}
              </label>
              <input
                type="text"
                id="hostname"
                name="hostname"
                formControlName="hostname"
                class="form-control form-control-sm"
                placeholder="{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.HOSTNAME.PLACEHOLDER' | translate }}"
              >
              @if (isLoading.hostname || hasError.hostname) {
                <div class="loading-popup">
                  @if (isLoading.hostname) {
                    <div class="loading"></div>
                  }
                  @if (hasError.hostname) {
                    <div class="text-white bg-danger p-2">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- Workgroup and language selection -->
      @if (canViewAndUpdate.workgroup) {
        <div class="row" formGroupName="workgroup">
          <div class="col-8 pr-3 form-group">
            <label class="w-100" for="workgroups">
              {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.NAME' | translate }}
            </label>
            <ng-select
              name="selectedWorkgroup"
              placeholder="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SELECT' | translate }}"
              [items]="workgroupsList || []"
              formControlName="selectedWorkgroup"
              (change)="form.get('workgroup')?.markAsTouched()"
              [clearable]="false"
            ></ng-select>
          </div>
          <div class="col-4 form-group">
            <label class="w-100" for="lang">
              {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.LANGUAGE' | translate }}
            </label>
            <ng-select
              name="selectedlang"
              placeholder="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SELECT' | translate }}"
              [items]="availableLanguagesList || []"
              formControlName="selectedlang"
              (change)="form.get('workgroup')?.markAsTouched()"
              [disabled]="!(form.get('workgroup')?.get('selectedWorkgroup')?.value)"
              [clearable]="false"
              [searchable]="false"
            ></ng-select>
            @if (form.get('workgroup')?.get('selectedlang')?.errors && isSubmitted) {
              <div class="text-danger form-field-error-message">
                @if (form.get('workgroup')?.get('selectedlang')?.errors?.required) {
                  <span>Language is required</span>
                }
              </div>
            }
          </div>
          @if (isLoading.workgroup || hasError.workgroup) {
            <div class="loading-popup">
              @if (isLoading.workgroup) {
                <div class="loading"></div>
              }
              @if (hasError.workgroup) {
                <div class="text-danger bg-white">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
              }
            </div>
          }
        </div>
      }

      <!-- Bandwidth throttling setting -->
      @if (canViewAndUpdate.bandwidthThrottling) {
        <div class="position-relative" formGroupName="bandwidth">
          <div>
            @if (!isSuccessSaved.throttling) {
              <span class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
            }
          </div>
          <div class="d-flex pt-3 pb-4">
            <mat-slide-toggle
              name="bandwidthSettings"
              [(ngModel)]="isBandwidthSettingsEnabled"
              [ngModelOptions]="{standalone: true}"
              (change)="form.get('bandwidth')?.markAsTouched()"
            >
              <span class="font-weight-lighter">{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.CHECKBOX_TEXT'  | translate }}</span>
            </mat-slide-toggle>
            <div
              class="ml-1 d-inline-flex align-items-center"
              matTooltip="{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO' | translate }}"
            >
              <img src="assets/icons/info.svg" alt="information">
            </div>
          </div>
          @if (isBandwidthSettingsEnabled) {
            <hr>
            <div class="mt-4">
              <div class="form-row">
                <div class="form-group col-12 col-md-3">
                  <label for="bandwidthLimit">
                    {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.LIMIT'  | translate }}
                  </label>
                  <input
                    id="bandwidthLimit"
                    formControlName="bandwidthLimit"
                    type="number"
                    min="10000"
                    class="form-control form-control-sm"
                    placeholder="Limit in b/s"
                  />
                  @if (form.get('bandwidth')?.get('bandwidthLimit')?.errors) {
                    <div class="text-danger form-field-error-message">
                      @if (form.get('bandwidth')?.get('bandwidthLimit')?.errors?.required) {
                        <span>Bandwidth Limit is required</span>
                      }
                      @if (form.get('bandwidth')?.get('bandwidthLimit')?.errors?.min) {
                        <span>Bandwidth Limit must be higher than 10000</span>
                      }
                    </div>
                  }
                </div>
                <div class="col-12 col-md-3 pl-md-3 mb-3 mb-md-0" style="margin-top: 37px;">
                  <a (click)="resetBandwidth()" class="primary">
                    <img src="assets/icons/undo.svg" alt="reverse" class="mb-1 mr-1">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_RESET' | translate }}
                  </a>
                </div>

                <div class="col-6 col-md-3 form-group">
                  <label for="starttime">
                    {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.START_TIME'  | translate }}
                  </label>
                  <input
                    type="time"
                    id="starttime"
                    formControlName="startTime"
                    name="starttime"
                    class="form-control form-control-sm"
                  />
                </div>
                <div class="col-6 col-md-3 form-group">
                  <label for="endtime">
                    {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.END_TIME'  | translate }}
                  </label>
                  <input
                    type="time"
                    id="endtime"
                    formControlName="endTime"
                    name="endtime"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="d-flex" style="color: #787878; width: 30%">
                <img class="mr-2 pb-3" src="assets/icons/info.svg" alt="information">
                <span class="pt-2">{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.INFO'  | translate }}</span>
              </div>
            </div>
          }
          @if (isLoading.throttling || hasError.throttling) {
            <div class="loading-popup">
              @if (isLoading.throttling) {
                <div class="loading"></div>
              }
              @if (hasError.throttling) {
                <div class="text-danger bg-white">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
              }
            </div>
          }
        </div>
      }
    </div>

    <hr style="margin-top: 38px; margin-bottom: 30px; margin-left: -44px; margin-right: -44px;">

    <div class="modal-footer border-0 p-0">
      @if (hasNothingChangedOnSave) {
        <span
          class="text-danger mr-2"
        >{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.NO_CHANGES' | translate }}</span>
      }
      <button
        type="button"
        class="btn btn-secondary"
        (click)="closePopup()"
      >{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
      <button
        type="button"
        (click)="save()"
        [disabled]="isLoading.retailerPlayerName || isLoading.hostname || isLoading.throttling || isLoading.workgroup"
        class="btn btn-primary ml-3"
      >{{ 'ADMIN_PAGES.SAVE' | translate }}</button>
    </div>
  </form>
}
