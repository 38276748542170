import { Injectable } from '@angular/core';
import { IOrgaentityAll, IOrgaentityClient, IOrgaentityTreeItem } from '@dep/common/interfaces';
import { Windows11UpgradeProductIndentifier } from '@dep/common/shop-api/enums/product.enum';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

import { OrgaentitiesService } from './orgaentities.service';

import { ProductModel as ShopProductModel } from '@dep/frontend/shop/models/product.model';

export type Windows11UpgradeAlertMessage = {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
};

type CategorizedHostnames = {
  upgradeWithoutHardware: string[];
  upgradeWithHardware: string[];
  ineligible: string[];
};

@Injectable({
  providedIn: 'root',
})
export class Windows11UpgradeService {
  constructor(
    private readonly logger: NGXLogger,
    private readonly ngxTranslate: TranslateService,
    private readonly orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
    * Get Windows 11 migration ready state of the orgaentity.
    *
    * @param item - Orgaentity item
    * @returns `upgraded` if the OS is already Windows 11, the Windows 11 readiness status from DEA otherwise
    *
    */
  public getWindows11MigrationReadyState(item: IOrgaentityAll): string {
    // Check if the operating system is already upgraded to Windows 11.
    if ((item as IOrgaentityClient).operatingSystem && (item as IOrgaentityClient).operatingSystem?.indexOf('Windows 11') !== -1) {
      return 'upgraded';
    }

    // Check the current state if the operating system is not yet upgraded to Windows 11.
    const val = this.orgaentitiesService.getOEDataValue(item, 'DEA_WIN11_READY');
    return val || 'unknown';
  }

  /**
    * Get Windows 11 migration order status.
    *
    * @param item - Orgaentity item
    */
  public getWindows11MigrationOrderStatus(item: IOrgaentityAll): string | null {
    return this.orgaentitiesService.getOEDataValue(item, 'windows11MigrationOrderStatus');
  }

  /**
   * Categorize selected players based on their eligibility for the Windows 11 upgrade.
   *
   * Eligible players are divided into two groups, while the rest is added to `ineligible` players:
   *  - `upgradeWithoutHardware`: Players eligible for an upgrade without additional hardware.
   *  - `upgradeWithHardware`: Players requiring hardware upgrades.
   *  - `ineligible`: Players not eligible for the upgrade.
   *
   * @param flatTree - All orgaentities from the cockpit tree
   */
  public categorizePlayersForUpgrade(
    flatTree: IOrgaentityTreeItem[],
  ): CategorizedHostnames {
    const selectedPlayersNeedingUpgrade = flatTree
      .filter((item) => item.selected
        && item.item.type === 'CLIENT'
        && (
          this.getWindows11MigrationOrderStatus(item.item) !== 'PAID'
          && this.getWindows11MigrationOrderStatus(item.item) !== 'CREATED'
        ));

    return selectedPlayersNeedingUpgrade.reduce((acc: CategorizedHostnames, item) => {
      const playerWindows11ReadyState = this.getWindows11MigrationReadyState(item.item);

      if (playerWindows11ReadyState.startsWith('ok') || playerWindows11ReadyState.startsWith('warning')) {
        acc.upgradeWithoutHardware.push((item.item as IOrgaentityClient).playername);
      } else if (playerWindows11ReadyState.startsWith('critical')) {
        acc.upgradeWithHardware.push((item.item as IOrgaentityClient).playername);
      } else if (playerWindows11ReadyState !== 'upgraded') {
        acc.ineligible.push((item.item as IOrgaentityClient).playername);
      }
      return acc;
    }, { upgradeWithoutHardware: [], upgradeWithHardware: [], ineligible: [] });
  }

  /**
   * Maps applicable Windows 11 upgrade products to a structured format for the product chooser popup.
   *
   * @param playersEligibility - Categorized players
   * @param applicableProducts - Available Windows 11 upgrade products
   */
  public mapApplicableProductsToPopupProducts(
    playersEligibility: CategorizedHostnames,
    applicableProducts: ShopProductModel[],
  ): { identifier: string | undefined; description: string; selectedEligiblePlayers: string[] | undefined }[] {
    const products = applicableProducts.map((applicableProduct) => {
      let selectedEligiblePlayers: string[] | undefined;
      if (applicableProduct.identifier === Windows11UpgradeProductIndentifier.Windows11Upgrade) {
        selectedEligiblePlayers = playersEligibility.upgradeWithoutHardware.length ? playersEligibility.upgradeWithoutHardware : undefined;
      } else if (
        applicableProduct.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardware
        || applicableProduct.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardwareMI
      ) {
        selectedEligiblePlayers = playersEligibility.upgradeWithHardware.length ? playersEligibility.upgradeWithHardware : undefined;
      }

      return {
        identifier: applicableProduct.identifier,
        description: `${applicableProduct.identifier}_DESCRIPTION`,
        selectedEligiblePlayers,
      };
    });

    return products;
  }

  /**
   * Generate alert messages to be displayed in the Windows 11 upgrade popup.
   *
   * @param orgaentitiesFlatTree - Orgaentities, used for filtering relevant players
   * @param playersEligibility - Categorized players
   * @param products - List of available Windows 11 upgrade products
   */
  public generateAlertMessages(
    orgaentitiesFlatTree: IOrgaentityTreeItem[],
    playersEligibility: CategorizedHostnames,
    products: {
      identifier: string | undefined;
      description: string;
      selectedEligiblePlayers: string[] | undefined;
    }[],
  ): Windows11UpgradeAlertMessage[] {
    const alertMessages: Windows11UpgradeAlertMessage[] = [];
    const playersWithoutApplicableProduct = this.getPlayersWithoutApplicableProduct(playersEligibility, products);
    const playersWithAlreadyCreatedOrder = orgaentitiesFlatTree.filter((item) => item.selected
      && item.item.type === 'CLIENT'
      && (
        this.getWindows11MigrationOrderStatus(item.item) === 'CREATED'
        || this.getWindows11MigrationOrderStatus(item.item) === 'PAID'
      ))
      .map((item) => (item.item as IOrgaentityClient).playername);

    // Show a warning message if user select players for upgrade but shop doesn't contain applicable product.
    if (
      playersWithoutApplicableProduct.length > 0
      || (products.length === 0 && playersEligibility.ineligible.length === 0)
    ) {
      alertMessages.push({
        message: this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.LIST.NO_APPLICABLE_PRODUCT_MESSAGE',
          { playersString: playersWithoutApplicableProduct.join(', ') },
        ),
        type: 'warning',
      });
    }
    // Show a warning message with ineligible players if there are any.
    if (playersEligibility.ineligible.length > 0) {
      alertMessages.push({
        message: this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.LIST.MBUI_INELIGIBLE_PLAYERS_MESSAGE',
          { playersString: playersEligibility.ineligible.join(', ') },
        ),
        type: 'warning',
      });
    }
    // Show a info message if the user selects players with already created order.
    if (playersWithAlreadyCreatedOrder.length > 0) {
      alertMessages.push({
        message: this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.LIST.ALREADY_CREATED_ORDER_MESSAGE',
          { playersString: playersWithAlreadyCreatedOrder.join(', ') },
        ),
        type: 'info',
      });
    }
    // Show an info message if the user must place multiple orders,
    // such as one for software-only upgrades and another for upgrades that include hardware.
    if (
      playersWithoutApplicableProduct.length === 0
      && playersEligibility.upgradeWithoutHardware.length > 0
      && playersEligibility.upgradeWithHardware.length > 0
      && products.length > 0
    ) {
      alertMessages.push({
        message: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.MBUI_ADDITIONAL_ORDER_MESSAGE'),
        type: 'info',
      });
    }

    this.logger.debug('Generated Windows 11 upgrade alert messages', alertMessages);

    return alertMessages;
  }

  /**
   * Returns a list of players that don't have an applicable Windows 11 upgrade product in the shop.
   *
   * @param playersEligibility - An object containing categorized players
   * @param products - A list of available Windows 11 upgrade products
   */
  private getPlayersWithoutApplicableProduct(
    playersEligibility: { upgradeWithoutHardware: string[]; upgradeWithHardware: string[] },
    products: { identifier: string | undefined }[],
  ): string[] {
    const playersWithoutApplicableProduct = new Set<string>();

    if (
      playersEligibility.upgradeWithoutHardware.length > 0
      && !products.some((product) => product.identifier === Windows11UpgradeProductIndentifier.Windows11Upgrade)
    ) {
      playersEligibility.upgradeWithoutHardware.forEach((player) => playersWithoutApplicableProduct.add(player));
    }

    if (
      playersEligibility.upgradeWithHardware.length > 0
      && !products.some(
        (product) => product.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardware
          || product.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardwareMI,
      )
    ) {
      playersEligibility.upgradeWithHardware.forEach((player) => playersWithoutApplicableProduct.add(player));
    }

    return [...playersWithoutApplicableProduct];
  }
}
