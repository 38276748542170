import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  type IDeviceMetricItem,
  type IOrgaentityClient,
  type IOrgaentityTreeItem,
} from '@dep/common/interfaces';
import { TranslateService } from '@ngx-translate/core';

import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'clientMetricValue',
})
export class ClientMetricValuePipe implements PipeTransform {
  constructor(
    private readonly ngxTranslate: TranslateService,
    private readonly translationService: TranslationService,
  ) { }

  /**
   * Get specific metric info, used for all client metrics except Connection (Online/Offline status) and Disk Space,
   * those two have their own methods to get live metric info
   * Icon colors can be Green(ok) | Yellow(warning) | Red(critical) | Grey(unknown)
   *
   * @param metricName DSSystem/Screenhost/Sophos/Date/OS/SpeedTest/RocketStatus/LenovoWarranty/OpenVPN
   */
  public transform(item: IOrgaentityTreeItem, metricName: string, metrics?: IDeviceMetricItem[]): IMetricInfo {
    const metricDetails = {
      status: 'grey',
      statusText: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'),
      lastUpdated: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED') + ': ' + this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
      foundMetric: true,
      name: (item.item as IOrgaentityClient).name, // Initialize name (retailer player name) with static data from tree.
      hostname: (item.item as IOrgaentityClient).playername, // Initialize hostname with static data from tree.
      location: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
      devicetype: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
      division: (item.item as IOrgaentityClient).division ?? this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
      version: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.VERSION') + ' ' + this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
    };
    if (metrics) {
      const metric = metrics.find((d) => d.name === metricName);
      if (!metric) {
        metricDetails.foundMetric = false;
        return metricDetails;
      }
      // DSSystem, Screenhost, Sophos (Security Software) and VPN Connection
      if (metric.type === 'status') {
        if (metric.value.search(/ok/i) !== -1) {
          metricDetails.status = 'green';
          metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.RUNNING');
        } else if (metric.value.search(/warning/i) !== -1) {
          metricDetails.status = 'yellow';
          metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.WARNING');
        } else if (metric.value.search(/critical/i) !== -1) {
          metricDetails.status = 'red';
          metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.CRITICAL');
        }
      }
      // OS, Rocket Status, Device Time and Lenovo Warranty
      if (metric.type === 'output') {
        if (metric.value.search(/ok/i) !== -1) {
          metricDetails.status = 'green';
          metricDetails.statusText = '';
          if (metricName === 'Date') {
            const splitString = (metric.value.slice(metric.value.search(/:\s/i) + 2)).split(' ');
            for (const str of splitString) {
              if (!str.match(/TimeZone:|UTC|^$/i)) {
                if (str.search(/\//gi) !== -1) {
                  metricDetails.statusText = this.translationService.formatDateTime(new Date(str)).slice(0, 10);
                } else {
                  metricDetails.statusText += ' ' + str.substring(0, str.length - 3);
                }
              }
            }
          } else if (metricName === 'LastTimeUp') {
            // Metric value example: "OK: 2022-08-23T06:25:53.865Z"
            const splitString = (metric.value.slice(metric.value.search(/:\s/i) + 2)).split(' ');
            let statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN');
            if (Date.parse(splitString[0]) > 0) {
              statusText = this.translationService.formatDateTime(new Date(splitString[0]));
            } else if (splitString[0]) {
              [statusText] = splitString;
            }
            metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_HEARTBEAT') + ': ' + statusText;
          } else if (metricName === 'LenovoWarranty') {
            const index = metric.value.search(/:\s/i);
            const tmp = this.translationService.formatDateTime(new Date(metric.value.slice(index + 2)));
            metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL') + ': ' + (tmp).substring(0, tmp.length - 5);
          } else { // Operating System and Rocket Status
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          }
        } else if (metric.value.search(/critical/i) !== -1) {
          metricDetails.status = 'red';
          if (metric.value.search(/windows/i) !== -1 || metricName === 'RocketStatus') {
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          } else {
            metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.CRITICAL');
          }
        } else if (metric.value.search(/warning/i) !== -1) {
          metricDetails.status = 'yellow';
          if (metricName === 'RocketStatus') {
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          } else {
            metricDetails.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.WARNING');
          }
        }
      }
      // Parse json type metrics (RCPSData, Internet Speed).
      if (metric.type === 'json') {
        try {
          const parsedArray = JSON.parse(metric.value) as Record<string, string | number>;

          // Format RCPSData.
          if (metric.name === 'RCPSData') {
            metricDetails.hostname = String(parsedArray.hostname) || (item.item as IOrgaentityClient).playername;
            if (typeof parsedArray.location === 'string') {
              metricDetails.location = this.translationService.formatClientLocationName(parsedArray.location);
            }
            if (typeof parsedArray.devicetype === 'string') {
              metricDetails.devicetype = this.translationService.formatClientDeviceType(parsedArray.devicetype);
            }
            if (typeof parsedArray.installedPlayerVersion === 'string') {
              metricDetails.version = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.VERSION') + ' ' + parsedArray.installedPlayerVersion;
            }
            if (typeof parsedArray.playerStatus === 'string') {
              // Set `statusText` to "UNKNOWN", "INACTIVE", "PLAYING", or a similar player status.
              metricDetails.statusText = parsedArray.playerStatus;

              // Set the `status` to
              //   GREEN  if PLAYING
              //   YELLOW if ACTIVE/INACTIVE
              //   RED    if UNKNOWN/OFFLINE
              switch (parsedArray.playerStatus) {
                case 'PLAYING':
                  metricDetails.status = 'green';
                  break;
                case 'ACTIVE':
                case 'INACTIVE':
                  metricDetails.status = 'yellow';
                  break;
                default:
                  metricDetails.status = 'red';
                  break;
              }
            }
          } else if (metric.name === 'ShowroomPresenterData') {
            const parsedShowroomPresenterData = JSON.parse(metric.value);

            // If any value is provided (here, the hostname is used), Showroom:Presenter is considered connected.
            if (parsedShowroomPresenterData.hostname) {
              metricDetails.status = 'green';
              metricDetails.statusText = '';
            }

            if (typeof parsedShowroomPresenterData.name === 'string') {
              metricDetails.name = parsedShowroomPresenterData.name;
            }

            if (typeof metric.updatedAt === 'string') {
              metricDetails.lastUpdated = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED') + ': ' + this.translationService.formatDateTime(
                new Date(metric.updatedAt),
              );
            }
          } else {
            // Format Internet Speed, Icon color Green | Grey.
            metricDetails.status = 'green';
            // TODO: What's the type here? Needs to be checked and fixed when there is any
            for (const obj of (parsedArray as any)) {
              if (obj.name === 'download') {
                metricDetails.statusText = 'DL: ' + Math.round(Number(obj.value)) + ' Mbit/s';
              }
              if (obj.name === 'upload') {
                metricDetails.statusText += ' UL: ' + Math.round(Number(obj.value)) + ' Mbit/s';
              }
            }
          }
        } catch (e) {
          // Could not parse and process metric value.
        }
      }
      // Last Updated
      if (metric.updatedAt && metric.updatedAt !== '') {
        metricDetails.lastUpdated = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED')
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return metricDetails;
  }
}

interface IMetricInfo {
  status: string;
  statusText: string;
  lastUpdated: string;
  foundMetric: boolean;
  name: string;
  hostname: string;
  location: string;
  devicetype: string;
  division?: string;
  version: string;
}
