import {
  type OrgaentityLocationContact,
  OrgaentityType,
  type OrgaentityUpdate,
} from '@dep/common/core-api/types/orgaentity.type';

// TODO: Replace `ClientModel` usages with corresponding zod schema.
export interface ClientModel {
  /** If `-1` or `undefined`, the client does not exist yet. */
  id: number | undefined;
  name?: string;
  type?: '' | 'COMPANY' | 'LOCATION' | 'CLIENT' | 'MATCHMAKER' | 'HANDOVERCOMPANION' | 'CARMEDIASTICK' | 'SHOWROOMCONNECT';
  orgaentitySubtypeId?: number | null;
  gssn?: string | null;
  brandCode?: string | null;
  level?: string | null;
  parentOrgaentityId?: number | null;
  validUntil?: string;
  division?: string | null;
  playername?: string | null;
  companyType?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  contacts?: OrgaentityLocationContact[] | null;
  mediaintegrator?: string | null;
  registrationDate?: string;
  hostname?: string;
  hardwarePlatine?: string;
  data?: Record<string, string> | null;
  dataEnhanced?: Record<string, string> | null;
}

/**
 * Convert data from `OrgaentityUpdate` to the legacy `ClientModel`.
 *
 * Can be removed once no method requires the `ClientModel` anymore.
 */
export function createClientModelFromOrgaentity(input: OrgaentityUpdate): ClientModel {
  let model: ClientModel = {
    id: input.id,
    name: input.name,
    type: input.type,
    orgaentitySubtypeId: input.orgaentitiesSubtypesId,
    gssn: input.gssn,
    brandCode: input.brandCode,
    level: input.level,
    parentOrgaentityId: input.parentOrgaentitiesId,
  };

  if (input.type === OrgaentityType.Company) {
    model = {
      ...model,
      companyType: input.companyType,
    };
  } else if (input.type === OrgaentityType.Location) {
    model = {
      ...model,
      street: input.street,
      city: input.city,
      zip: input.zip,
      country: input.country,
      contacts: input.contacts,
      mediaintegrator: input.mediaintegrator,
    };
  } else if (input.type === OrgaentityType.Client) {
    model = {
      ...model,
      validUntil: input.validUntil,
      division: input.division,
      playername: input.hostname,
    };
  } else if (input.type === OrgaentityType.Carmediastick) {
    model = {
      ...model,
      registrationDate: input.registrationDate,
      hostname: input.hostname,
      hardwarePlatine: input.hardwarePlatine,
    };
  }

  return model;
}
