<h1 *ngIf="isCreateMode()">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CREATE_ORGAENTITY' | translate }}</h1>
<h1 *ngIf="canEdit && !isCreateMode() && orgaentity.type !== 'CLIENT'">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_ORGAENTITY' | translate }}</h1>
<h1 *ngIf="canEdit && clientId !== -1 && orgaentity.type === 'CLIENT'">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_CLIENT' | translate }}</h1>
<h1 *ngIf="!canEdit && orgaentity.type === 'CLIENT'">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SHOW_CLIENT' | translate }}</h1>
<h1 *ngIf="orgaentity.type === 'MATCHMAKER' && isShowMode()">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SHOW_MATCHMAKER' | translate }}</h1>

<form id="clientForm" (ngSubmit)="save()" #clientForm="ngForm">
  <div *ngIf="isLoading" class="loading-form-popup">
    <div class="loading"></div>
  </div>

  <div class="form-group" *ngIf="orgaentity.type !== 'CLIENT' && orgaentity.type !== 'MATCHMAKER'" [class.col-md-12]="orgaentity.type === 'CARMEDIASTICK'">
    <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.TYPE' | translate }}</p>
    <ng-select
      name="oeType"
      [(ngModel)]="orgaentity.type"
      (change)="onTypeChange($event)"
      [disabled]="formDisabled || !canEdit || isLoading || !canChangeType()"
      [clearable]="false"
      [searchable]="false"
    >
      <ng-option value="" *ngIf="canChangeTypeTo('')">NONE</ng-option>
      <ng-option value="COMPANY" *ngIf="canChangeTypeTo('COMPANY')">COMPANY</ng-option>
      <ng-option value="LOCATION" *ngIf="canChangeTypeTo('LOCATION')">LOCATION</ng-option>
      <ng-option value="CLIENT" *ngIf="canChangeTypeTo('CLIENT')">CLIENT</ng-option>
      <ng-option value="MATCHMAKER" *ngIf="canChangeTypeTo('MATCHMAKER')">WELCOMEASSISTANT</ng-option>
      <ng-option value="CARMEDIASTICK" *ngIf="canChangeTypeTo('CARMEDIASTICK')">CARMEDIASTICK</ng-option>
      <ng-option value="SHOWROOMCONNECT" *ngIf="canChangeTypeTo('SHOWROOMCONNECT')">SHOWROOMCONNECT</ng-option>
    </ng-select>
  </div>
  <div class="form-row">
    <div *ngIf="orgaentity.type !== 'CLIENT' && orgaentity.type !== 'MATCHMAKER' && orgaentity.type !== 'CARMEDIASTICK' && orgaentity.type !== 'SHOWROOMCONNECT'" class="form-group col-md-6" [class.form-row-error]="gssn.invalid && gssn.dirty && gssn.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.GSSN' | translate }}</p>
      <input type="text" name="gssn" class="form-control" [(ngModel)]="orgaentity.gssn" #gssn="ngModel" placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" pattern="[0-9]{7,8}" />
    </div>
    <div *ngIf="orgaentity.type !== 'CLIENT' && orgaentity.type !== 'MATCHMAKER' && orgaentity.type !== 'CARMEDIASTICK' && orgaentity.type !== 'SHOWROOMCONNECT'" class="form-group col-md-6" [class.form-row-error]="brand.invalid && brand.dirty && brand.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BRAND_CODE' | translate }}</p>
      <input type="text" name="brandCode" class="form-control" [(ngModel)]="orgaentity.brandCode" #brand="ngModel" placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
    </div>
  </div>
  <div class="form-group" [class.form-row-error]="name.invalid && name.dirty && name.touched">
    <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.NAME' | translate }}*</p>
    <input type="text" name="name" class="form-control" [(ngModel)]="orgaentity.name" #name="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
  </div>
  <div class="form-row">
    <div class="form-group col-md-6" [class.col-md-12]="orgaentity.type === 'CLIENT' || orgaentity.type === 'CARMEDIASTICK' || orgaentity.type === 'MATCHMAKER'">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PARENT' | translate }}</p>
      <div>
        <ng-select
          *ngIf="!isLoadingParentOrgaentities"
          name="oe"
          [items]="selectOrgaentities || []"
          [disabled]="formDisabled || !canEdit || isLoading || selectOrgaentities === null"
          [(ngModel)]="orgaentity.parentOrgaentitiesId"
          [clearable]="false"
        ></ng-select>
        <div *ngIf="isLoadingParentOrgaentities" class="loading"></div>
      </div>
      <div *ngIf="selectOrgaentities === null" class="loading"></div>
    </div>
    <div *ngIf="orgaentity.type === '' || orgaentity.type === 'COMPANY' || orgaentity.type === 'LOCATION'" class="form-group col-md-6">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LEVEL' | translate }}</p>
      <div>
        <ng-select
          name="level"
          [items]="selectLevel"
          [disabled]="formDisabled || !canEdit || isLoading"
          [(ngModel)]="orgaentity.level"
          [searchable]="false"
          [clearable]="false"
        ></ng-select>
      </div>
      <div *ngIf="selectOrgaentities === null" class="loading"></div>
    </div>
  </div>
  <hr class="my-3" *ngIf="orgaentity.type !== '' && orgaentity.type !== 'MATCHMAKER'">

  <!--
    CLIENT
  -->
  <ng-container *ngIf="orgaentity.type === 'CLIENT'">
    <div class="form-row align-items-end">
      <div class="form-group col-8" style="min-height: 68px;">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SUBTYPE' | translate }}</p>
        <ng-select
          *ngIf="!isLoadingOrgaentitySubtypes"
          name="orgaentitySubtypes"
          [items]="selectOrgaentitySubtypes || []"
          [(ngModel)]="orgaentity.orgaentitiesSubtypesId"
          [disabled]="formDisabled || !canEdit || isLoading || selectOrgaentitySubtypes === null"
          [clearable]="true"
          class="custom"
        ></ng-select>
        <div *ngIf="isLoadingOrgaentitySubtypes" class="loading"></div>
      </div>
    </div>
    <div class="form-row align-items-end" [class.form-row-error]="validUntil.invalid && validUntil.dirty && validUntil.touched">
      <div class="form-group col-8">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.VALID_UNTIL' | translate }}*</p>
        <input type="text" name="validUntil" class="form-control" [(ngModel)]="orgaentity.validUntil" #validUntil="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
      </div>
      <div class="col-3 ml-2 mb-2">
        <p><a class="primary" href="javascript: void(0);" (click)="orgaentity.validUntil = '2026-01-01T00:00:00.000Z'">Set to 2025-12-31</a></p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-8">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.DIVISION' | translate }}</p>
        <ng-select
          name="divisions"
          [items]="selectDivisions"
          [(ngModel)]="orgaentity.division"
          [disabled]="formDisabled || !canEdit || isLoading"
        ></ng-select>
      </div>
    </div>
    <div class="form-group" [class.form-row-error]="playername.invalid && playername.dirty && playername.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PLAYERNAME' | translate }}*</p>
      <input type="text" name="playername" class="form-control" [(ngModel)]="orgaentity.hostname" #playername="ngModel" required pattern="[A-Z][0-9a-zA-Z]{14}" placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
    </div>
  </ng-container>

  <!--
    COMPANY
  -->
  <ng-container *ngIf="orgaentity.type === 'COMPANY'">
    <div class="form-group">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.COMPANY_TYPE' | translate }}</p>
      <ng-select
        name="type"
        [(ngModel)]="orgaentity.companyType"
        [clearable]="false"
        [disabled]="formDisabled || editIsLoading()"
        [searchable]="false"
      >
        <ng-option value="Own Retail">Own Retail</ng-option>
        <ng-option value="Independent">Independent</ng-option>
      </ng-select>
    </div>
  </ng-container>

  <!--
    LOCATION
  -->
  <ng-container *ngIf="orgaentity.type === 'LOCATION'">
    <div class="form-group" [class.form-row-error]="street.invalid && street.dirty && street.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.STREET' | translate }}</p>
      <input
        type="text"
        name="street"
        class="form-control"
        [(ngModel)]="orgaentity.street"
        #street="ngModel"
        placeholder=""
        [class.no-edit]="!canEdit"
        [disabled]="formDisabled || !canEdit || isLoading"
      />
    </div>

    <div class="form-row">
      <div class="form-group col-md-6" [class.form-row-error]="city.invalid && city.dirty && city.touched">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CITY' | translate }}</p>
        <input
          type="text"
          name="city"
          class="form-control"
          [(ngModel)]="orgaentity.city"
          #city="ngModel"
          placeholder=""
          [class.no-edit]="!canEdit"
          [disabled]="formDisabled || !canEdit || isLoading"
        />
      </div>
      <div class="form-group col-md-6" [class.form-row-error]="zip.invalid && zip.dirty && zip.touched">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ZIP' | translate }}</p>
        <input
          type="text"
          name="zip"
          class="form-control"
          [(ngModel)]="orgaentity.zip"
          #zip="ngModel"
          maxlength="10"
          placeholder=""
          [class.no-edit]="!canEdit"
          [disabled]="formDisabled || !canEdit || isLoading"
        />
      </div>
    </div>

    <div class="form-group pb-3">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.COUNTRY' | translate }}</p>
      <ng-select
        name="countries"
        [items]="selectCountries"
        [(ngModel)]="orgaentity.country"
        [disabled]="formDisabled"
        [clearable]="false"
      ></ng-select>
    </div>
    <hr *ngIf="orgaentity.contacts && orgaentity.contacts.length">

    <div *ngIf="orgaentity.contacts && orgaentity.contacts.length" class="form-row pt-4">
      <div *ngFor="let contact of orgaentity.contacts" class="form-group col-md-6">
        <h3>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.' + contact.contactType | translate }}</h3>
        <input type="text" name="c_name_{{ contact.contactType }}" class="form-control mb-3" [(ngModel)]="contact.name" #c_name="ngModel" placeholder="Name" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
        <input type="text" name="c_email_{{ contact.contactType }}" class="form-control mb-3" [(ngModel)]="contact.mail" #c_email="ngModel" placeholder="E-mail" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
        <input type="text" name="c_phone_{{ contact.contactType }}" class="form-control mb-3" [(ngModel)]="contact.phone" #c_phone="ngModel" placeholder="Phone" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
      </div>
    </div>
    <hr>

    <div class="form-group pt-4">
      <h5>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.MEDIA_INTEGRATOR' | translate }}</h5>
      <div *ngFor="let mediaintegrator of mediaintegrators; let i = index; trackBy: arrayIndex" class="row mb-3">
        <div class="col-10 col-md-6">
          <input
            type="text"
            name="mediaintegrators-{{ i }}"
            #mediaIntegrator="ngModel"
            [(ngModel)]="mediaintegrators[i]"
            class="form-control"
            [disabled]="isLoading"
            pattern="[^,]*"
            [required]="i > 0 || (i === 0 && mediaintegrators.length > 1)"
            [class.form-field-error]="mediaIntegrator.invalid && mediaIntegrator.touched"
          />
        </div>
        <a *ngIf="mediaintegrators.length > 1" href="javascript: void(0)" class="img-wrapper" (click)="mediaintegrators.splice(i, 1)">
          <img src="assets/icons/delete.svg" [alt]="'ADMIN_PAGES.CLIENTS.DETAILS.REMOVE_MEDIA_INTEGRATOR' | translate" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.REMOVE_MEDIA_INTEGRATOR' | translate" />
        </a>
      </div>
      <div>
        <img src="assets/icons/plus_blue.svg" alt="+" height="14px" class="mb-1"><a href="javascript: void(0)" (click)="mediaintegrators.push('')" class="ml-1 primary">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ADD_MEDIA_INTEGRATOR' | translate }}</a>
      </div>
    </div>
  </ng-container>

  <!--
    C@Rmedia Stick
  -->
  <ng-container *ngIf="orgaentity.type === 'CARMEDIASTICK'">
    <div class="form-group" [class.form-row-error]="hostName.invalid && hostName.dirty && hostName.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PLAYERNAME_OR_HOSTNAME' | translate }}*</p>
      <input type="text" name="hostName" class="form-control" [(ngModel)]="orgaentity.hostname" #hostName="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit || isLoading" />
    </div>
    <div class="form-group"[class.form-row-error]="hardwarePlatine.invalid && hardwarePlatine.dirty && hardwarePlatine.touched">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.HARDWARE_PLATINE' | translate }}*</p>
      <ng-select
        name="hardwarePlatine"
        #hardwarePlatine="ngModel"
        [items]="selectHardwareType"
        [(ngModel)]="orgaentity.hardwarePlatine"
        [disabled]="formDisabled || isLoading"
        [clearable]="false"
        [searchable]="false"
        [required]="true"
      ></ng-select>
    </div>
    <div class="form-group">
      <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ARRIVAL_DATE' | translate }}*</p>
      <input
        type="text"
        name="registrationDateInput"
        #registrationDateInput="ngModel"
        [(ngModel)]="orgaentity.registrationDate"
        placeholder="01.01.1900"
        minlength="10"
        maxlength="10"
        class="form-control no-edit pl-0"
        autocomplete="off"
        readonly
      />
    </div>
  </ng-container>

  <!--
    `data` object inputs
  -->
  <div *ngIf="dataInputs && orgaentity.data" class="row">
    <ng-container *ngFor="let dataInput of dataInputs">
      <div class="form-group col-md-6">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.INPUTS.' + (dataInput.name | uppercase) | translate }}*</p>
        <input
          *ngIf="dataInput.type === 'datetime'"
          type="text"
          [name]="dataInput.name + 'Input'"
          [(ngModel)]="orgaentity.data[dataInput.name]"
          placeholder="YYYY-MM-DD"
          minlength="10"
          maxlength="10"
          class="form-control"
          autocomplete="off"
        />
      </div>
    </ng-container>
  </div>

  <div>
    <hr style="margin-left: -44px; margin-right: -44px; margin-bottom: 30px; margin-top: 54px;">
    <div class="row">
      <div class="col-4 pt-0">
        <button type="button" *ngIf="canEdit && clientId !== -1" class="btn btn-danger mr-2" (click)="remove()" [disabled]="formDisabled"><img class="mb-2" src="assets/icons/delete.svg"> {{ 'ADMIN_PAGES.CLIENTS.DETAILS.DELETE' | translate }}</button>
      </div>
      <div class="col-8 text-right">
        <button type="button" class="btn btn-secondary mr-3" (click)="cancel()" [disabled]="formDisabled">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CANCEL' | translate }}</button>
        <button type="submit" class="btn btn-primary" [disabled]="!canEdit || !clientForm.form.valid || formDisabled || isLoading || isLoadingParentOrgaentities">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SAVE_CLIENT' | translate }}</button>
      </div>
    </div>
  </div>
</form>

<app-info-modal
  *ngIf="confirmModal"
  [visible]="confirmModal.visible"
  [dialogType]="confirmModal.dialogType"
  [title]="confirmModal.title"
  [content]="confirmModal.content"
  [confirm]="confirmModal.confirm"
  [confirmButtonText]="confirmModal.confirmButtonText || ''"
  [dismiss]="confirmModal.dismiss"
  [dismissButtonText]="confirmModal.dismissButtonText || ''"
  [confirmButtonType]="confirmModal.confirmButtonType || 'primary'"
></app-info-modal>
