import { inject, NgModule } from '@angular/core';
import {
  CanActivateFn,
  CanLoadFn,
  RouterModule,
  Routes,
} from '@angular/router';
import { RoleRight } from '@dep/common/interfaces';
import { RoleRight as ShopRoleRight } from '@dep/common/shop-api/enums/role-right.enum';

import { AdminRootComponent } from './pages/admin/admin-root/admin-root.component';
import { ClientsListComponent } from './pages/admin/clients-list/clients-list.component';
import { ConfigurationComponent } from './pages/admin/configuration/configuration.component';
import { ConnectedshowroomComponent } from './pages/admin/connectedshowroom/connectedshowroom.component';
import { ConnectedShowroomQrCodeGeneratorComponent } from './pages/admin/connectedshowroom/qrcode-generator/qrcode-generator.component';
import { ContactsDetailsComponent } from './pages/admin/contacts-details/contacts-details.component';
import { ContactsListComponent } from './pages/admin/contacts-list/contacts-list.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UsersDetailsComponent } from './pages/admin/users-details/users-details.component';
import { UsersListComponent } from './pages/admin/users-list/users-list.component';
import { CurationPanelComponent } from './pages/curation-panel/curation-panel.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginInternalComponent } from './pages/login-internal/login-internal.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { AuthGuard } from './services/auth.guard';

import { LicensesAssignmentComponent } from '@dep/frontend/pages/admin/licenses-assignment/licenses-assignment.component';
import { LicensesListComponent } from '@dep/frontend/pages/admin/licenses-list/licenses-list.component';

const canActivateFn: CanActivateFn = (route) => inject(AuthGuard).canActivate(route);
const canLoadFn: CanLoadFn = () => inject(AuthGuard).canLoad(); // TODO: Use canMatch instead?

const routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/internal',
    component: LoginInternalComponent,
  },
  {
    path: 'login/register/internal',
    component: RegistrationComponent,
  },
  {
    path: 'login/register/sso',
    component: RegistrationComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'carshow',
    redirectTo: 'login/register',
  },
  {
    path: '',
    component: AdminRootComponent,
    canActivate: [canActivateFn],
    data: { rights: [] },
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'clients',
        component: ClientsListComponent,
        canActivate: [canActivateFn],
        // TODO: Could be changed to `[RoleRight.ORGAENTITY_READ]` after the backend is able to handle requests with that right only (e. g. DEP.user role).
        data: { rightsOr: [RoleRight.CLIENT_READ, RoleRight.CARSHOWADAPTER_READ, RoleRight.CARMEDIASTICK_READ] },
      },
      {
        path: 'licenses',
        component: LicensesListComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.LMS_LICENSES_READ, RoleRight.LMS_LICENSES_UPDATE] },
      },
      {
        path: 'assign-licenses/:playername',
        component: LicensesAssignmentComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.LMS_LICENSES_READ, RoleRight.LMS_LICENSES_UPDATE] },
      },
      {
        path: 'shop',
        canLoad: [canLoadFn],
        loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
        data: { rights: [ShopRoleRight.ShopRead] },
      },
      {
        path: 'shop/orders',
        canLoad: [canLoadFn],
        loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
        data: { rights: [ShopRoleRight.ShopRead] },
      },
      {
        path: 'contacts',
        component: ContactsListComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.CONTACT_READ] },
      },
      {
        path: 'contacts/:contactId',
        component: ContactsDetailsComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.CONTACT_READ] },
      },
      {
        path: 'users',
        component: UsersListComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.USER_READ] },
      },
      {
        path: 'users/:userId',
        component: UsersDetailsComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.USER_READ] },
      },
      {
        path: 'files',
        loadChildren: () => import('./files/files.module').then((m) => m.FilesModule),
      },
      {
        path: 'supporttickets',
        loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
        data: { rights: [RoleRight.SUPPORTTICKET_READ] },
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.CONFIG_UPDATE] },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [canActivateFn],
        data: { rightsOr: [RoleRight.REPORTING_READ, RoleRight.NETWORK_REPORTING_READ] },
      },
      {
        path: 'forms/instances',
        canLoad: [canLoadFn],
        loadChildren: () => import('./forms/forms.module').then((m) => m.FormsModule),
        data: { rights: [RoleRight.FORMINSTANCE_READ] },
      },
      {
        path: 'curation-panel',
        canActivate: [canActivateFn],
        component: CurationPanelComponent,
        data: { rights: [RoleRight.AUTOMATEDCONTENT_BASIC] },
      },
      {
        path: 'connectedshowroom',
        component: ConnectedshowroomComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.CONNECTEDSHOWROOM] },
      },
      {
        path: 'connectedshowroom/qr-code-generator',
        component: ConnectedShowroomQrCodeGeneratorComponent,
        canActivate: [canActivateFn],
        data: { rights: [RoleRight.CONNECTEDSHOWROOM] },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin/clients',
  },
] satisfies Routes;

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
