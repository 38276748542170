@if (!isFocusModeActive) {
  <ul class="mb-3 d-lg-none">
    <a (click)="menuCollapsed = !menuCollapsed">{{ 'ADMIN_PAGES.MENU' | translate }} <img class="menu-open-icon" src="assets/icons/arrow_down.png" srcset="assets/icons/arrow_down@2x.png 2x"></a>
  </ul>
  <div class="d-lg-block mb-3 user-select-none" [class.d-none]="menuCollapsed">
    @if (isLoading) {
      <div class="loading loading-white loading-absolute"></div>
    }

    <ul class="mb-3 menu-list" [class.menu-list-is-loading]="isLoading">
      @for (menuItem of menu; track menuItem.name) {
        @if (!(menuItem.slug | startsWith: 'https://')) {
          <a
            [routerLink]="menuItem.subItems ? undefined : ['/' + menuItem.slug]"
            routerLinkActive
            #routerLinkActiveReference=routerLinkActive
            [ngClass]="{'active': routerLinkActiveReference.isActive && !(menuItem.name === 'ADMIN_MENU.SHOP' && currentSlug === 'shop/orders')}"
            (click)="
              menuItem.subItems ? (isMenuItemExpanded = !isMenuItemExpanded) : (menuCollapsed = true);
              setCurrentSlug(menuItem.slug);
            "
            class="d-flex align-items-center"
          >
            @if (menuItem.icon) {
              <img src="assets/icons/side-navigation/{{ menuItem.icon }}" class="mr-2">
            }
            <div class="ml-1">{{ menuItem.name | translate }}</div>

            <!-- Orders indicator -->
            @if (menuItem.slug === 'shop/orders' && isOrdersIndicatorVisible) {
              <span class="tooltip-custom">
                <span class="orders-indicator ml-1"></span>
                <span class="tooltiptext">{{ 'ADMIN_PAGES.ORDERS.ORDERS_INDICATOR_TOOLTIPTEXT' | translate }}</span>
              </span>
            }

            <!-- TODO: Sub-items need to use the same or similar logic as the normal menu items, like `routerLinkActive` for determining the active menu item. -->
            <!-- Subitem icon -->
            @if (menuItem.subItems) {
              <span class="sub-item-arrow">
                <img src="assets/icons/{{ isMenuItemExpanded ? 'arrow_up' : 'arrow_down' }}.png" alt="Arrow">
              </span>
            }
          </a>
          <!-- Subitems -->
          @if (menuItem.subItems && isMenuItemExpanded) {
            @for (subItem of menuItem.subItems; let i = $index; track subItem) {
              <a
                [routerLink]="['/' + menuItem.slug + '/' + subItem.slug]"
                class="d-flex align-items-center"
                [ngClass]="{'active': activeSubItemIndex === i}"
                (click)="activeSubItemIndex = i"
              >
                <div class="sub-item">{{ subItem.name | translate }}</div>
              </a>
            }
          }
        }
        @else if (menuItem.slug | startsWith: 'https://') {
          <!-- URL link (external) -->
          <a
            href="{{ menuItem.slug }}"
            target="_blank"
            rel="noopener noreferrer"
            class="d-flex"
          >
            @if (menuItem.icon) {
              <img src="assets/icons/side-navigation/{{ menuItem.icon }}" class="mr-2">
            }
            <div class="ml-1">{{ menuItem.name | translate }}</div>
          </a>
        }
      }
    </ul>

    <app-help-desk></app-help-desk>
  </div>
}
