import {
  Component,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  OrgaentityLevel,
  OrgaentityType,
  type OrgaentityUpdate,
} from '@dep/common/core-api/types/orgaentity.type';
import { getCountries } from '@dep/common/countries';
import {
  type IOrgaentity,
  RoleRight,
  type BaseResponse,
  type IOrgaentityAll,
} from '@dep/common/interfaces';
import type { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { ModalComponentOptions } from '@dep/frontend/blocks/info-modal/info-modal.component';
import { createClientModelFromOrgaentity } from '@dep/frontend/models/client';
import { LocationsService } from '@dep/frontend/services/locations.service';
import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { OrgaentitySubtypesService } from '@dep/frontend/services/orgaentity-subtypes.service';
import {
  type IPopup,
  PopupService,
} from '@dep/frontend/services/popup.service';
import { UserService } from '@dep/frontend/services/user.service';
import { emptyStringsToNull } from 'src/utils/empty-strings-to-null.util';

@Component({
  selector: 'app-popup-edit-clients',
  templateUrl: './edit-clients.component.html',
  styleUrls: ['./edit-clients.component.scss'],
})
export class PopupEditClientsComponent implements OnInit {
  // Need to import JSON file here to load the orgaentity types.
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  public static readonly ORGAENTITY_TYPES: IOrgaentityType[] = require('../../services/orgaentity-types.json');

  @ViewChild('clientForm') public clientForm?: NgForm;
  public popup?: IPopup;
  public orgaentity: OrgaentityUpdate = this.resetOrgaentity();

  /** Contains the `data` inputs that should be displayed for this orgaentity type. */
  public dataInputs?: IOrgaentityType['inputs'];
  public mediaintegrators: string[] = ['']; // Initialize with one empty item/input.
  public clientId?: number;
  public canEdit = false;
  public formDisabled = false;
  public isLoading = false;
  public isLoadingOrgaentitySubtypes = false;
  /** Reflects whether the list of orgaentitites is still loading. */
  public isLoadingParentOrgaentities = false;

  public selectOrgaentities?: NgOption[];
  public selectLevel: NgOption[];
  public selectOrgaentitySubtypes?: NgOption[];
  public selectRoles?: NgOption[];
  public selectCountries: NgOption[] = [];
  public selectHardwareType: NgOption[] = [
    {
      value: 'Raspberry Pi Zero W',
      label: 'Raspberry Pi Zero W',
    },
    {
      value: 'Raspberry Pi Zero 2 W',
      label: 'Raspberry Pi Zero 2 W',
    },
  ];

  public selectDivisions: NgOption[] = [
    { value: 'Mercedes-Benz PKW', label: 'Mercedes-Benz PKW' },
    { value: 'Mercedes-Benz Vans', label: 'Mercedes-Benz Vans' },
    { value: 'Mercedes-Benz Service', label: 'Mercedes-Benz Service' },
  ];

  public confirmModal?: ModalComponentOptions;

  constructor(
    private logger: NGXLogger,
    private toastr: ToastrService,
    private orgaentitiesService: OrgaentitiesService,
    private orgaentitySubtypesService: OrgaentitySubtypesService,
    private locationsService: LocationsService,
    private ngxTranslate: TranslateService,
    private userService: UserService,
    private popupService: PopupService,
  ) {
    this.selectLevel = Object.values(OrgaentityLevel).map((level) => ({
      value: level,
      label: level === OrgaentityLevel.None ? '(None)' : level,
    }));

    const countriesCodes = Object.entries(getCountries());
    this.selectCountries = countriesCodes.map((c) => ({
      value: c[1],
      label: c[1],
    }));
  }

  public async ngOnInit(): Promise<void> {
    this.resetOrgaentity();
    this.resetModal();

    this.formDisabled = false;

    this.orgaentity.parentOrgaentitiesId = Number(this.popup?.settings.customValues?.parentId);
    this.logger.debug('Editing client', this.popup?.settings.customValues, this.orgaentity);

    // Handle creation of new clients.
    if (this.popup?.settings.customValues?.clientId === '-1') {
      this.clientId = -1;
      this.canEdit = await this.userService.hasRight(RoleRight.ORGAENTITY_CREATE);
      this.loadOrgaentities();
      this.loadOrgaentitySubtypes();
      return;
    }

    try {
      this.isLoading = true;

      this.clientId = Number(this.popup?.settings.customValues?.clientId);

      // const oe = await this.orgaentitiesService.getOrgaentity(this.clientId);
      this.orgaentity = await this.orgaentitiesService.getOrgaentityV2(this.clientId);

      // If `level` is `null`, set it to the enum `None` value to be able to use the value in the `<select>`.
      if (!this.orgaentity.level) {
        this.orgaentity.level = OrgaentityLevel.None;
      }

      this.canEdit = await this.userService.hasRight(RoleRight.ORGAENTITY_UPDATE);
      switch (this.orgaentity.type) {
        case OrgaentityType.Location: {
          // Split comma-separated media integrators to display them in separate inputs.
          if (this.orgaentity.mediaintegrator) {
            this.mediaintegrators = this.orgaentity.mediaintegrator.split(',');
          }

          const { country } = this.orgaentity;
          if (country !== null && this.selectCountries.findIndex((sc) => sc.value === country) < 0) {
            this.selectCountries.push({
              value: country,
              label: country,
            });
          }
          break;
        }
        default:
          break;
      }

      this.initializeDataInputs(this.orgaentity.type);

      this.isLoading = false;
    } catch (err: any) {
      this.logger.error(`Client ${String(this.clientId)} could not be loaded`, err);

      const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');

      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.INFO_NOTFOUND_TEXT', { name: this.orgaentity.name })) + '<br /><small>' + errMsg + '</small>',
        String(this.ngxTranslate.instant('ADMIN_PAGES.INFO_NOTFOUND_TITLE')),
      );
      // Close popup because the item was not found.
      this.cancel();
    }

    this.loadOrgaentities();

    this.loadOrgaentitySubtypes();
  }

  /**
   * Initialize the `dataInputs` variable by getting the inputs for the given `orgaentityType`.
   *
   * If `model.data` is not defined yet, it is initialized to an empty object.
   */
  private initializeDataInputs(orgaentityType: IOrgaentityAll['type']): void {
    this.dataInputs = PopupEditClientsComponent.ORGAENTITY_TYPES.find((type) => type.name === orgaentityType)?.inputs;
    if (this.dataInputs && !this.orgaentity.data) {
      this.orgaentity.data = {};
    }

    // Preprocess `data` values.
    const orgaentityTypeDef = PopupEditClientsComponent.ORGAENTITY_TYPES.find((type) => type.name === this.orgaentity.type.valueOf());
    if (orgaentityTypeDef) {
      for (const dataInput of orgaentityTypeDef.inputs) {
        if (this.orgaentity.data?.hasOwnProperty(dataInput.name)) {
          switch (dataInput.type) {
            case 'datetime': {
              if (typeof this.orgaentity.data[dataInput.name] !== 'string') {
                this.logger.error('Expected "datetime" value to be a string', typeof this.orgaentity.data[dataInput.name]);
                continue;
              }
              this.orgaentity.data[dataInput.name] = String(this.orgaentity.data[dataInput.name]).substring(0, 10);
              break;
            }
            default:
              break;
          }
        }
      }
    }
  }

  private async loadOrgaentities(): Promise<void> {
    this.isLoadingParentOrgaentities = true;
    try {
      let oes = await this.locationsService.getOrgaentities();
      if (this.clientId) {
        oes = this.removeChildren(oes, [this.clientId]);
      }
      this.selectOrgaentities = oes.map((oe) => ({
        value: oe.id,
        label: oe.name + (oe.gssn ? ' | GSSN: ' + oe.gssn : ''),
      }));
      this.selectOrgaentities.unshift({
        value: '',
        label: '- None -',
      });
    } catch (loadingError: any) {
      this.logger.error('Loading orgaentities list failed', loadingError);

      const errorMessage = loadingError.message ?? (loadingError.errors && loadingError.errors.length ? loadingError.errors[0].message : 'Unknown');

      this.toastr.error(
        String(errorMessage),
        String(this.ngxTranslate.instant('ADMIN_PAGES.LOADING_FAILED')),
      );

      // Close popup because the item cannot be saved without the parent selection.
      this.cancel();
    } finally {
      this.isLoadingParentOrgaentities = false;
    }
  }

  /**
   * Tracks form changes and syncs the recent changes in UI.
   * Find more: https://stackoverflow.com/questions/47843856/angular-what-is-the-point-of-implementing-trackby
   */
  public arrayIndex(i: number): number {
    return i;
  }

  private removeChildren(oes: IOrgaentity[], ids: number[]): IOrgaentity[] {
    if (ids.length === 0) {
      return oes;
    }
    const nextIds = [];
    for (const id of ids) {
      for (let i = 0; i < oes.length; i++) {
        if (oes[i].parentOrgaentityId === id) {
          nextIds.push(oes[i].id);
          oes.splice(i, 1);
          i--;
        }
      }
    }

    return this.removeChildren(oes, nextIds);
  }

  private async loadOrgaentitySubtypes(): Promise<void> {
    this.isLoadingOrgaentitySubtypes = true;
    const orgaentitySubtypes = await this.orgaentitySubtypesService.getSubtypes();

    this.selectOrgaentitySubtypes = orgaentitySubtypes.map((subtype) => ({
      value: subtype.id,
      label: subtype.name,
    }));
    this.isLoadingOrgaentitySubtypes = false;
  }

  private resetOrgaentity(): OrgaentityUpdate {
    this.orgaentity = {
      id: -1,
      type: OrgaentityType.None,
    };
    return this.orgaentity;
  }

  public cancel(): void {
    this.formDisabled = false;
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  public async save(): Promise<void> {
    this.formDisabled = true;

    let orgaentityClone = JSON.parse(JSON.stringify(this.orgaentity)) as OrgaentityUpdate;
    orgaentityClone = emptyStringsToNull(orgaentityClone, [
      'gssn',
      'brandCode',
      'level',
      'companyType',
      'street',
      'city',
      'zip',
      'country',
      'hostname',
      'division',
      'hardwarePlatine',
    ]);

    if (orgaentityClone.type === OrgaentityType.Location) {
      orgaentityClone.mediaintegrator = this.mediaintegrators.map((mediaintegrator) => mediaintegrator.trim()).join(',');
    }

    try {
      // Remove DEA values for now, because they will be kept in `orgaentities_data` instead of `orgaentities.data`.
      if (orgaentityClone.data) {
        for (const dataKey of Object.keys(orgaentityClone.data)) {
          if (dataKey.startsWith('DEA_')) {
            delete orgaentityClone.data[dataKey];
          }
        }
      }

      // Preprocess `data` values.
      if (orgaentityClone.type) {
        const orgaentityType = PopupEditClientsComponent.ORGAENTITY_TYPES.find((type) => type.name === orgaentityClone.type.valueOf());
        if (orgaentityType) {
          for (const dataInput of orgaentityType.inputs) {
            if (orgaentityClone.data?.hasOwnProperty(dataInput.name)) {
              switch (dataInput.type) {
                case 'datetime': {
                  try {
                    const datetimeValue = orgaentityClone.data[dataInput.name];
                    if (typeof datetimeValue !== 'string' || !/^([0-9][0-9][0-9][0-9])-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/.test(datetimeValue)) {
                      throw new Error('Not matching date format YYYY-MM-DD');
                    }
                    orgaentityClone.data[dataInput.name] = new Date(datetimeValue).toISOString();
                  } catch (datetimeError) {
                    throw new Error('Invalid date value');
                  }
                  break;
                }
                default:
                  break;
              }
            }
          }
        }
      }

      this.logger.debug('Saving orgaentity', orgaentityClone);

      const savePromises: Promise<BaseResponse>[] = [];

      if (orgaentityClone.id === -1) {
        const orgaentityCloneLegacyModel = createClientModelFromOrgaentity(orgaentityClone);
        savePromises.push(this.orgaentitiesService.createOrgaentity(orgaentityCloneLegacyModel));
      } else {
        savePromises.push(this.orgaentitiesService.updateOrgaentity(orgaentityClone));
      }

      this.isLoading = true;
      await Promise.all(savePromises);
      this.formDisabled = false;

      const canEdit = await this.userService.hasRight(RoleRight.ORGAENTITY_UPDATE);
      if (this.popup) {
        if (canEdit) {
          this.isLoading = false;

          if (this.popup.callbacks.onClientSave) {
            this.popup.callbacks.onClientSave({
              name: orgaentityClone.name,
              gssn: orgaentityClone.gssn,
              brandCode: orgaentityClone.brandCode,
              division: orgaentityClone.type === OrgaentityType.Client ? orgaentityClone.division : undefined,
            });
          }

          this.toastr.success(
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.SAVE_SUCCESS', { name: orgaentityClone.name })),
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.SAVE_SUCCESS_TITLE')),
          );

          this.resetModal.bind(this);
        }

        this.popupService.close(this.popup.uuid);
      }
    } catch (err: any) {
      const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');

      this.logger.error('Could not save client', this.orgaentity, err);
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.SAVE_FAILED', { name: orgaentityClone.name })) + '<br /><small>' + errMsg + '</small>',
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.SAVE_FAILED_TITLE')),
      );

      this.formDisabled = false;
      this.isLoading = false;
    }
  }

  public async remove(): Promise<void> {
    this.formDisabled = true;

    this.logger.debug('Removing client', this.orgaentity.id);

    // Open delete confirmation modal.
    this.confirmModal = {
      dialogType: 'error',
      title: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.CONFIRM_DELETE_TITLE', { name: this.orgaentity.name }),
      content: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.CONFIRM_DELETE'),
      visible: true,
      dismiss: () => {
        this.formDisabled = false;
        this.resetModal();
      },
      dismissButtonText: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.CANCEL'),
      confirm: this.removeDo.bind(this),
      confirmButtonText: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.DELETE'),
      confirmButtonType: 'danger',
    };
  }

  private async removeDo(): Promise<void> {
    if (this.orgaentity.id < 0) {
      this.logger.error('Could not remove client because it does not have a valid ID', this.orgaentity);
      return;
    }

    try {
      await this.orgaentitiesService.removeOrgaentity(this.orgaentity.id);
      this.toastr.success(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.DELETE_SUCCESS', { name: this.orgaentity.name })),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.DELETE_SUCCESS_TITLE')),
      );

      this.formDisabled = false;
      this.resetOrgaentity();

      // Reload clients tree and close the popup.
      if (this.popup?.callbacks.onClientDelete) {
        this.popup.callbacks.onClientDelete();
        this.popupService.close(this.popup.uuid);
      }
    } catch (err: any) {
      const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');

      this.logger.error('Could not remove client', this.orgaentity, err);
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.DELETE_FAILED', { name: this.orgaentity.name })) + '<br /><small>' + errMsg + '</small>',
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.DELETE_FAILED_TITLE')),
      );

      this.formDisabled = false;
    }
  }

  private resetModal(): void {
    this.logger.debug('Modal reset');

    this.confirmModal = undefined;
  }

  /**
   * Checks whether the main form (OE) is still loading.
   */
  public editIsLoading(): boolean {
    return this.clientId !== -1 && !this.orgaentity.id;
  }

  public onTypeChange(event: string): void {
    if (event === 'CARMEDIASTICK') {
      if (this.orgaentity.type === OrgaentityType.Carmediastick && !this.orgaentity.registrationDate) {
        this.orgaentity.registrationDate = new Date().toISOString().slice(0, 10);
      }
    }

    if (event === 'SHOWROOMCONNECT') {
      if (!this.orgaentity.name) {
        this.orgaentity.name = 'Showroom:Connect';
      }
    }

    this.initializeDataInputs(event as IOrgaentityAll['type']);
  }

  public isCreateMode(): boolean {
    return this.clientId === -1;
  }

  public isEditMode(): boolean {
    return this.clientId !== undefined && this.canEdit;
  }

  public isShowMode(): boolean {
    return this.clientId !== undefined && !this.canEdit;
  }

  public canChangeType(): boolean {
    return (
      this.orgaentity.id === -1
      || (
        this.orgaentity.type === OrgaentityType.None
        || this.orgaentity.type === OrgaentityType.Company
        || this.orgaentity.type === OrgaentityType.Location
      )
    );
  }

  public canChangeTypeTo(type: string): boolean {
    if (this.orgaentity.id === -1) {
      return true;
    }
    if (this.orgaentity.type.valueOf() === type) {
      return true;
    }
    // TODO: Enable this when backend and reporting are able to handle type changes.
    // if (this.model.type === '' || this.model.type === 'COMPANY' || this.model.type === 'LOCATION') {
    //   return type === '' || type === 'COMPANY' || type === 'LOCATION';
    // }

    return false;
  }
}

interface IOrgaentityType {
  name: string;
  label: string;
  inputs: {
    name: string;
    type: 'datetime';
  }[];
}
