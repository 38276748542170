<div class="metadata-container">
  <div *ngIf="row.item.type === 'CLIENT' || row.item.type === 'CARMEDIASTICK'" class="container flex-column clients-list-details" [class.clients-list-details-expand]="row.expanded">
    <div class="pl-4 row">
      <hr class="w-100" />
    </div>
    <!-- C@Rmedia Stick -->
    <!--
      Currently C@Rmedia Stick has no RCPS Data,
      using hostname from TreeItem
    -->
    <div *ngIf="row.item.type === 'CARMEDIASTICK'" class="pl-4 row">
      <div class="col-12 col-sm-4">
        <span>{{ 'ADMIN_PAGES.CLIENTS.LIST.HOSTNAME' | translate }}:</span>
        <img *ngIf="isMetricsLoading" class="spinner ml-2" src="assets/loading_spinner.gif" alt="Loading">
        <span [class.d-none]="isMetricsLoading" class="pl-1 break-all">{{ (row.item | asClient).hostname }}</span>
      </div>
    </div>

    <!-- Digital Signage -->
    <div *ngIf="row.item.type === 'CLIENT'" class="pl-4 py-2">
      <div>
        <span class="font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.LIST.HOSTNAME' | translate }}:</span>
        <img *ngIf="isMetricsLoading" class="spinner ml-2" src="assets/loading_spinner.gif" alt="Loading">
        <span [class.d-none]="isMetricsLoading" class="pl-1 font-weight-bold">{{ (row | clientMetricValue: 'RCPSData': metrics).hostname }}</span>
      </div>
      <div class="pt-2 row">
        <div class="col-12 col-sm-4">
          <span>{{ 'ADMIN_PAGES.CLIENTS.LIST.LOCATION' | translate }}:</span>
          <img *ngIf="isMetricsLoading" class="spinner ml-2" src="assets/loading_spinner.gif" alt="Loading">
          <span [class.d-none]="isMetricsLoading" class="pl-1">{{ (row | clientMetricValue: 'RCPSData': metrics).location }}</span>
        </div>
        <div class="col-12 col-sm-4">
          <span>{{ 'ADMIN_PAGES.CLIENTS.LIST.DEVICE_TYPE' | translate }}:</span>
          <img *ngIf="isMetricsLoading" class="spinner ml-2" src="assets/loading_spinner.gif" alt="Loading">
          <span [class.d-none]="isMetricsLoading" class="pl-1">{{ (row | clientMetricValue: 'RCPSData': metrics).devicetype }}</span>
        </div>
        <div class="col-12 col-sm-4">
          <span>{{ 'ADMIN_PAGES.CLIENTS.LIST.DIVISION' | translate }}:</span>
          <img *ngIf="isMetricsLoading" class="spinner ml-2" src="assets/loading_spinner.gif" alt="Loading">
          <span [class.d-none]="isMetricsLoading" class="pl-1">{{ (row | clientMetricValue: 'RCPSData': metrics).division }}</span>
        </div>
      </div>
    </div>
    <div class="pl-4 pt-1 pb-3 row">
      <hr class="w-100" />
    </div>
  </div>

  <!-- <ng-template #metric let-row="row">
    <div class="d-flex flex-row mb-4">
      <div class="icon-client-metrics mr-1">
        <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
        <span class="tooltip-custom" style="cursor: help;">
          <img [class.d-none]="isMetricsLoading" src="assets/icons/check_{{ (metrics | clientOnlineStatus).status }}.svg" />
          <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.CLIENT_ICON_TEXT_'+ (metrics | clientOnlineStatus).status.toUpperCase() | translate }}</span>
        </span>
      </div>
      <div [class.pl-2]="isMetricsLoading">
        <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.CONNECTION'| translate }}</p>
        <p class="clients-list-details-status-details">{{ (metrics | clientOnlineStatus).statusText }}</p>
        <p class="clients-list-details-status-details">{{ (metrics | clientOnlineStatus).lastUpdated }}</p>
      </div>
    </div>
  </ng-template> -->

  <!-- Client Status Details -->
  <div *ngIf="row.item.type === 'CLIENT'" class="clients-list-details col-12 pl-sm-4">
    <div class="container">
      <div class="row">
        <!-- Online Status -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4">
            <div class="icon-client-metrics mr-1">
              <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help;">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/check_{{ (metrics | clientOnlineStatus).status }}.svg" />
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.CLIENT_ICON_TEXT_'+ (metrics | clientOnlineStatus).status | uppercase | translate }}</span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.CONNECTION'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (metrics | clientOnlineStatus).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (metrics | clientOnlineStatus).lastUpdated }}</p>
            </div>
          </div>
        </div>

        <!-- #2 VPN Connection -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4">
            <div class="mr-1 icon-client-metrics">
              <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/vpn_{{ (row | clientMetricColor: 'OpenVPN': metrics) }}.svg" alt="VPN Connection">
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.VPN_ICON_TEXT_'+ (row | clientMetricColor: 'OpenVPN': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.VPN_CONNECTION'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'OpenVPN': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'OpenVPN': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>

        <!-- Operating System -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4">
            <div class="mr-1 icon-client-metrics">
              @if (isMetricsLoading) {
                <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              } @else {
                <span class="tooltip-custom" style="cursor: help">
                  <img [class.d-none]="isMetricsLoading" src="assets/icons/windows_{{ (row | clientMetricColor: 'OS': metrics) }}.svg"/>
                  <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.OS_ICON_TEXT_'+ (row | clientMetricColor: 'OS': metrics) | uppercase | translate }} </span>
                </span>
              }
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.OPERATING_SYSTEM'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'OS': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'OS': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>

        <!-- Disk Usage -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4">
            <div class="mr-1 icon-client-metrics">
              <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/disk_status_{{ (row | clientMetricColor: 'DiskSpace': metrics) }}.svg" [alt]="'ADMIN_PAGES.CLIENTS.LIST.DISK_USAGE' | translate">
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.DISKSPACE_ICON_TEXT_'+ (row | clientMetricColor: 'DiskSpace': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.DISK_USAGE' | translate }}</p>
              <p class="clients-list-details-status-details">{{ checkDiskSpace('DiskSpace').statusText }}</p>
              <p class="clients-list-details-status-details">{{ checkDiskSpace('DiskSpace').lastUpdated }}</p>
            </div>
          </div>
        </div>

        <!-- Internet Speed (TEMPORARILY REMOVED AND REPLACED BY DEVICE TIME) -->
        <!-- <div class="d-flex flex-row mb-2">
          <div class="mr-1 icon-client-metrics">
            <img *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
            <span class="tooltip-custom" style="cursor: help">
              <img [class.d-none]="isMetricsLoading" src="assets/icons/internetspeed_{{ (row | clientMetricColor: 'Speedtest': metrics) }}.svg" alt="Internet Speed">
              <span class="tooltiptext">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.INTERNET_SPEED_ICON_TEXT_'+ (row | clientMetricColor: 'Speedtest': metrics) | uppercase | translate }} </span>
            </span>
          </div>
          <div>
            <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.INTERNET_SPEED'| translate }}</p>
            <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Speedtest': metrics).statusText }}</p>
            <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Speedtest': metrics).lastUpdated }}</p>
          </div>
        </div> -->

        <!-- Device Time -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4">
            <div class="mr-1 icon-client-metrics">
              <img class="spinner mb-1" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/check_{{ (row | clientMetricColor: 'Date': metrics) }}.svg"/>
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.DEVICE_TIME_ICON_TEXT_'+ (row | clientMetricColor: 'Date': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.DEVICE_TIME'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Date': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Date': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>

        <!-- Show additional information -->
        <div class="col-12 col-sm-4">
          <div class="d-flex flex-row mb-4" [class.ml-1]="isMetricsLoading">
            <div class="mr-1 icon-client-metrics">
              <img src="assets/icons/info.svg" alt="Information">
            </div>
            <!-- Show additional Information Text -->
            <div style="text-align: left">
              <p class="clients-list-details-status">
                <a (click)="row.infoExpanded = !row.infoExpanded" class="primary">
                  {{ row.infoExpanded ? ('ADMIN_PAGES.CLIENTS.LIST.SHOW_LESS_INFO' | translate) : ('ADMIN_PAGES.CLIENTS.LIST.SHOW_MORE_INFO' | translate) }}
                  <img class="arrowright" src="assets/icons/arrow_right_blue.svg">
                </a>
              </p>
              <p class="clients-list-details-status-details">{{ countMetricStatus() }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Expanded Information -->
  <div *ngIf="row.expanded && row.item.type === 'CLIENT' && row.infoExpanded"><hr class="my-2">
    <div class="clients-list-details col-12 pl-sm-4 flex-column flex-sm-row" style="height: auto !important;">
      <div class="col-12 col-sm-4">
        <!-- first column -->
        <div class="d-flex flex-column">
          <div class="d-flex flex-row mb-2">
            <!-- row #1 Sophos Service -->
            <div class="mr-1 icon-client-metrics">
              <img class="spinner" *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" height="17px" width="17px" src="assets/icons/sophos_{{ (row | clientMetricColor: 'Sophos': metrics) }}.svg" [alt]="'ADMIN_PAGES.CLIENTS.LIST.SOPHOS_SERVICE' | translate">
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.SOPHOS_ICON_TEXT_'+ (row | clientMetricColor: 'Sophos': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.SOPHOS_SERVICE' | translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Sophos': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Sophos': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- TODO: Temporarily moved because of broken internet speed metric. (d-none) -->
      <div class="d-none col-12 col-sm-4">
        <!-- second column -->
        <div class="d-flex flex-column">
          <!-- row #1 Device Time -->
          <div class="d-flex flex-row mb-2">
            <div class="mr-1 icon-client-metrics">
              <img *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/device_time_{{ (row | clientMetricColor: 'Date': metrics) }}.svg"/>
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.DEVICE_TIME_ICON_TEXT_'+ (row | clientMetricColor: 'Date': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.DEVICE_TIME'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Date': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'Date': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- TODO: Temporarily removed because the metric is broken. (d-none) -->
      <div class="d-none col-12 col-sm-4">
        <!-- third column -->
        <div class="d-flex flex-column">
          <!-- row #1 Lenovo Waranty -->
          <div class="d-flex flex-row mb-2">
            <div class="mr-1 icon-client-metrics">
              <img *ngIf="isMetricsLoading" src="assets/loading_spinner.gif" alt="Loading">
              <span class="tooltip-custom" style="cursor: help">
                <img [class.d-none]="isMetricsLoading" src="assets/icons/vpn_new_{{ (row | clientMetricColor: 'LenovoWarranty': metrics) }}.svg" alt="Lenovo Warranty">
                <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.LENOVO_ICON_TEXT_'+ (row | clientMetricColor: 'LenovoWarranty': metrics) | uppercase | translate }} </span>
              </span>
            </div>
            <div [class.pl-2]="isMetricsLoading">
              <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.LENOVO_WARRANTY'| translate }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'LenovoWarranty': metrics).statusText }}</p>
              <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'LenovoWarranty': metrics).lastUpdated }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Stage Wall Content -->
  <div
    *ngIf="
      row.item.type === 'CLIENT'
      && ((row.item | asClient).playername | clientTypeAbbreviation) === 'wa'
      && (RoleRight.CLIENT_CONTENT_CHANGE | userHasRight: row.item | async)
    "
    class="container flex-column clients-list-details"
  >
    <div class="pl-4 pt-2 row">
      <hr class="w-100" />
    </div>
    <div class="pl-4 pl-sm-0 pr-5 pt-1 row text-nowrap">
      <div class="col-12 col-sm-6 pr-0 text-sm-right">
        <span class="font-weight-bold pl-1">{{ 'ADMIN_PAGES.CLIENTS.LIST.ASSIGNED_CONTENT' | translate }}:</span>
      </div>
      <div class="col-12 col-sm-6 pl-sm-0 d-table-cell">
        <img *ngIf="isContentLoading" class="ml-2 spinner" src="assets/loading_spinner.gif" alt="Loading">
        <span *ngIf="row.assignedContent && row.assignedContent.length > 0" [class.d-none]="isContentLoading" class="pl-1 align-middle">
          {{ row.assignedContentModel || 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.INDIVIDUAL_CONTENT' | translate }}
        </span>
        <span *ngIf="row.assignedContent && row.assignedContent.length === 0" [class.d-none]="isContentLoading" class="pl-1 text-danger align-middle">
          {{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.NO_CONTENT' | translate }}
        </span>
        <span *ngIf="!row.assignedContent" [class.d-none]="isContentLoading" class="pl-1 text-warning align-middle">
          {{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.UNKNOWN' | translate }}
        </span>
        <a (click)="openRcpsContentEditPopup(row)" title="{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.TITLE' | translate }}" class="no-underline ml-2" [class.d-none]="isContentLoading" [class.disabled]="!row.assignedContent">
          <img class="align-text-bottom" src="assets/icons/edit_circle.svg"/>
        </a>
      </div>
    </div>
  </div>

  <!-- Licenses -->
  <!-- TODO: If the Showroom:Presenter condition should be changed back to the license check, replace the icon color check with something like: row.item | asClient | clientHasLicense: enumOneMirrorLicense.NAME -->
  @if (
    row.item.type === 'CLIENT'
    && ((row.item | asClient).orgaentitySubtypeId === 1 || (row | clientMetricColor: 'ShowroomPresenterData': metrics) === 'green')
  ) {
    <div
      class="container flex-column clients-list-details"
      [class.clients-list-details-expand]="row.expanded"
    >
      <div class="pl-4 pt-3 row">
        <hr class="w-100"/>
      </div>
      <div
        (click)="licensesExpanded = !licensesExpanded"
        class="pl-4 row"
        style="cursor: pointer;"
      >
        <div class="col-12 col-sm-4" style="margin-top: 10px;">
          <span *ngIf="!licensesExpanded">
            <img src="assets/icons/plus.svg" class="expand-icon" [title]="'Licenses: ' + row.item.name" />
          </span>
          <span *ngIf="licensesExpanded">
            <img src="assets/icons/minus.svg" class="expand-icon" style="vertical-align: 3px;" [title]="'Licenses: ' + row.item.name" />
          </span>
          <span class="client-clickable prevent ml-2">
            <img class="license-icon" src="assets/icons/key_grey.svg">
            {{ 'ADMIN_PAGES.CLIENTS.LIST.LICENSES' | translate }}
          </span>
        </div>
      </div>

      <!-- License Information -->
      @if (licensesExpanded) {
        <!-- TODO: Replace Sales Tablet check with a proper DS license check after a DS license has been introduced to LMS. -->
        @if ((row.item | asClient).orgaentitySubtypeId === 1) {
          <div class="pl-4 pt-1 row">
            <hr class="w-100"/>
          </div>
          <div class="pl-4 row">
            <div class="col-12 pl-4 pb-2">
              <span class="font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.LIST.RCPS_PLAYER' | translate }}: {{ row.item.name }}</span>
              <!-- Retailer player name edit popup. The name can be changed by any user who has access to the corresponding player. -->
              @if ((RoleRight.CLIENT_READ | userHasRight: row.item | async)) {
                <a
                  (click)="openRcpsSettingsRetailerPlayerNamePopup(row)"
                  [title]="'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_RETAILER_PLAYER_NAME' | translate"
                  class="no-underline ml-2 tooltip-custom"
                  style="vertical-align: 2px;"
                >
                  <img src="assets/icons/edit.svg" srcset="assets/icons/edit.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_RETAILER_PLAYER_NAME' | translate }}</span>
                </a>
              }
            </div>
          </div>
          <div class="pl-4 pb-1 row">
            <hr class="w-100"/>
          </div>

          <!-- DS Information -->
          <div class="clients-list-details pl-sm-5 row">
            <!-- DS Service -->
            <div class="col-12 col-sm-4">
              <div class="d-flex flex-row mb-2">
                <div class="mr-1 icon-client-metrics">
                  <img *ngIf="isMetricsLoading" class="spinner" src="assets/loading_spinner.gif" alt="Loading">
                  <span class="tooltip-custom" style="cursor: help">
                    <img [class.d-none]="isMetricsLoading" src="assets/icons/DS_service_{{ (row | clientMetricColor: 'RCPSData': metrics) }}.svg" [alt]="'ADMIN_PAGES.CLIENTS.LIST.DS_SERVICE' | translate">
                    <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.DS_SERVICE_ICON_TEXT_'+ (row | clientMetricColor: 'RCPSData': metrics) | uppercase | translate }}</span>
                  </span>
                </div>
                <div [class.pl-2]="isMetricsLoading">
                  <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.DS_SERVICE' | translate }}</p>
                  <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'RCPSData': metrics).version }}</p>
                  <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'LastTimeUp': metrics).statusText }}</p>
                </div>
              </div>
            </div>

            <!-- Migration Status -->
            <div class="col-12 col-sm-4 pl-4">
              <div class="d-flex flex-column">
                <div *ngIf="row.item | clientIsRCPSPlayer" class="d-flex flex-row mb-2">
                  <div class="mr-1 icon-client-metrics">
                    <img *ngIf="isMetricsLoading" class="spinner" src="assets/loading_spinner.gif" alt="Loading">
                    <span class="tooltip-custom" style="cursor: help">
                      <img [class.d-none]="isMetricsLoading" src="assets/icons/DS_service_settings_{{ (row | clientMetricColor: 'RocketStatus': metrics) }}.svg" alt="Rocket Status">
                      <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.DS_PLAYER_ICON_TEXT_'+ (row | clientMetricColor: 'RocketStatus': metrics) | uppercase | translate }} </span>
                    </span>
                  </div>
                  <div [class.pl-2]="isMetricsLoading">
                    <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.ROCKET_STATUS'| translate }}</p>
                    <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'RocketStatus': metrics).statusText }} </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Digital Signage License -->
            <div class="col-12 col-sm-4 pl-4">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row mb-2">
                  <div class="mr-1 icon-client-metrics">
                    <span class="tooltip-custom">
                      <img class="license-icon" src="assets/icons/key_{{ isExpired((row.item | asClient).validUntil) ? 'red' : 'green' }}.svg">
                    </span>
                  </div>
                  <div [class.pl-2]="isMetricsLoading">
                    <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.LICENSE' | translate }}</p>
                    <p class="clients-list-details-status-details">{{ 'ADMIN_PAGES.CLIENTS.LIST.STATUS' | translate }}: {{ (getValidityStatus((row.item | asClient).validUntil) | translate).toLowerCase() }}</p>
                    <p class="clients-list-details-status-details">{{ 'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL' | translate }}: {{ (row.item | asClient).validUntil | dateDayBefore | date: 'dd.MM.yyyy' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <!-- DS Information -->

        <!-- Showroom:Presenter License -->
        <!-- TODO: If the condition should be changed back to the license check, use something like: row.item | asClient | clientHasLicense: enumOneMirrorLicense.NAME -->
        @if ((row | clientMetricColor: 'ShowroomPresenterData': metrics) === 'green') {
          <div class="pl-4 pt-1 row">
            <hr class="w-100"/>
          </div>
          <div class="pl-4 row">
            <div class="col-12 col-sm-4 pb-2" style="padding-left: 32px;">
              <span [style.white-space]="'nowrap'" class="font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.LIST.ONEMIRROR' | translate }}: {{ (row | clientMetricValue: 'ShowroomPresenterData': metrics).name }}</span>
            </div>
          </div>
          <div class="pl-4 pb-1 row">
            <hr class="w-100" />
          </div>

          <div class="clients-list-details pl-sm-5 row">
            <!-- Showroom:Presenter Service -->
            <div class="col-12 col-sm-6">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row mb-2">
                  <div class="mr-1 icon-client-metrics">
                    @if (isMetricsLoading) {
                      <img class="spinner" src="assets/loading_spinner.gif" alt="Loading">
                    }
                    <span class="tooltip-custom" style="cursor: help">
                      <img [class.d-none]="isMetricsLoading" src="assets/icons/showroompresenter_circle_{{ (row | clientMetricColor: 'ShowroomPresenterData': metrics) }}.svg" width="19px" />
                      <span class="tooltiptext-metrics">{{ 'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.ST_SERVICE_ICON_TEXT_'+ (row | clientMetricColor: 'Screenhost': metrics) | uppercase | translate }} </span>
                    </span>
                  </div>
                  <div [class.pl-2]="isMetricsLoading">
                    <p class="clients-list-details-status">{{ 'ADMIN_PAGES.CLIENTS.LIST.ONEMIRROR_SERVICE' | translate }}</p>
                    <p class="clients-list-details-status-details">{{ (row | clientMetricValue: 'ShowroomPresenterData': metrics).lastUpdated }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <!--/ OneMirror License -->
      }
    </div>
  }
</div>
